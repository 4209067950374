// ===============================================================================
// ORGANISMES
// ===============================================================================

//Menus produits des games -> Vertical
.menu-gammes ul > li,
.sous-menu-gammes ul > li {
  //Sanitaire
  &.color-menu--#{$color-menu-san}:hover, &.color-menu--#{$color-menu-san}.isopensubmenu, &.color-menu--#{$color-menu-san}.is-current-active {
    > a {
      background: $san;

      &:after {
        border-color: $san transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $san;
        }
      }
    }
  }

  //collecte dechets
  &.color-menu--#{$color-menu-dech}:hover, &.color-menu--#{$color-menu-dech}.isopensubmenu, &.color-menu--#{$color-menu-dech}.is-current-active {
    > a {
      background: $dech;

      &:after {
        border-color: $dech transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $dech;
        }
      }
    }
  }

  //EQUIPEMENT DE NETTOYAGE
  &.color-menu--#{$color-menu-pmat}:hover, &.color-menu--#{$color-menu-pmat}.isopensubmenu, &.color-menu--#{$color-menu-pmat}.is-current-active {
    > a {
      background: $pmat;

      &:after {
        border-color: $pmat transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $pmat;
        }
      }
    }
  }

  //MATERIEL ET EQUIPEMENT ELEC
  &.color-menu--#{$color-menu-gmat}:hover, &.color-menu--#{$color-menu-gmat}.isopensubmenu, &.color-menu--#{$color-menu-gmat}.is-current-active {
    > a {
      background: $gmat;

      &:after {
        border-color: $gmat transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $gmat;
        }
      }
    }
  }

  //EQUIPEMENT DE PROTECTION INDIV
  &.color-menu--#{$color-menu-epi}:hover, &.color-menu--#{$color-menu-epi}.isopensubmenu, &.color-menu--#{$color-menu-epi}.is-current-active {
    > a {
      background: $epi;

      &:after {
        border-color: $epi transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $epi;
        }
      }
    }
  }

  //RESTAURATION/HOTELLERIE
  &.color-menu--#{$color-menu-reshot}:hover, &.color-menu--#{$color-menu-reshot}.isopensubmenu, &.color-menu--#{$color-menu-reshot}.is-current-active {
    > a {
      color: $black;
      background: $reshot;

      &:after {
        border-color: $reshot transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          color: $black;
          background: $reshot;
        }
      }
    }
  }

  //AUTRE MATERIEL ET FOURN INDUS
  &.color-menu--#{$color-menu-autrmat}:hover, &.color-menu--#{$color-menu-autrmat}.isopensubmenu, &.color-menu--#{$color-menu-autrmat}.is-current-active {
    > a {
      background: $autrmat;

      &:after {
        border-color: $autrmat transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $autrmat;
        }
      }
    }
  }

  //INNOVEYR
  // @TODO : ID de menu en variables & 1 build par env.
  &.color-menu--#{$color-menu-innoveyr}:hover, &.color-menu--#{$color-menu-innoveyr}.isopensubmenu, &.color-menu--#{$color-menu-innoveyr}.is-current-active {
    > a {
      background: $innoveyr;

      &:after {
        border-color: $innoveyr transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $innoveyr;
        }
      }
    }
  }

  //SOLS PROTEGES
  &.color-menu--#{$color-menu-solp}:hover, &.color-menu--#{$color-menu-solp}.isopensubmenu, &.color-menu--#{$color-menu-solp}.is-current-active {
    > a {
      background: $solp;

      &:after {
        border-color: $solp transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $solp;
        }
      }
    }
  }

  //SOLS SPECIFIQUES
  &.color-menu--#{$color-menu-sols}:hover, &.color-menu--#{$color-menu-sols}.isopensubmenu, &.color-menu--#{$color-menu-sols}.is-current-active {
    > a {
      background: $sols;

      &:after {
        border-color: $sols transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $sols;
        }
      }
    }
  }

  //HYGIENE GENERALE
  &.color-menu--#{$color-menu-hyg}:hover, &.color-menu--#{$color-menu-hyg}.isopensubmenu, &.color-menu--#{$color-menu-hyg}.is-current-active {
    > a {
      background: $hyg;

      &:after {
        border-color: $hyg transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $hyg;
        }
      }
    }
  }

  //ALIMENTAIRE
  &.color-menu--#{$color-menu-ali}:hover, &.color-menu--#{$color-menu-ali}.isopensubmenu, &.color-menu--#{$color-menu-ali}.is-current-active {
    > a {
      background: $ali;
      color: $black;

      &:after {
        border-color: $ali transparent transparent transparent;
      }
    }

    > a:after {
      border-color: $ali transparent transparent transparent;
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $ali;
          color: $black;
        }
      }
    }
  }

  //HYGIENE DU LINGE
  &.color-menu--#{$color-menu-linge}:hover, &.color-menu--#{$color-menu-linge}.isopensubmenu, &.color-menu--#{$color-menu-linge}.is-current-active {
    > a {
      background: $linge;

      &:after {
        border-color: $linge transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $linge;
        }
      }
    }
  }

  //PRODUITS PRO
  &.color-menu--#{$color-menu-pro}:hover, &.color-menu--#{$color-menu-pro}.isopensubmenu, &.color-menu--#{$color-menu-pro}.is-current-active {
    > a {
      background: $pro;

      &:after {
        border-color: $pro transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $pro;
        }
      }
    }
  }

  //ORMEA
  &.color-menu--#{$color-menu-orm}:hover, &.color-menu--#{$color-menu-orm}.isopensubmenu, &.color-menu--#{$color-menu-orm}.is-current-active {
    > a {
      background: $orm;

      &:after {
        border-color: $orm transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $orm;
        }
      }
    }
  }

  //ECOCERT
  &.color-menu--#{$color-menu-ecocert}:hover, &.color-menu--#{$color-menu-ecocert}.isopensubmenu, &.color-menu--#{$color-menu-ecocert}.is-current-active {
    > a {
      background: $ecocert;

      &:after {
        border-color: $ecocert transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $ecocert;
        }
      }
    }
  }

  //ECOLABEL
  &.color-menu--#{$color-menu-ecolabel}:hover, &.color-menu--#{$color-menu-ecolabel}.isopensubmenu, &.color-menu--#{$color-menu-ecolabel}.is-current-active {
    > a {
      background: $ecoabel;

      &:after {
        border-color: $ecoabel transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $ecoabel;
        }
      }
    }
  }

  //DISQUES
  &.color-menu--#{$color-menu-disques}:hover, &.color-menu--#{$color-menu-disques}.isopensubmenu, &.color-menu--#{$color-menu-disques}.is-current-active {
    > a {
      background: $disques;

      &:after {
        border-color: $disques transparent transparent transparent;
      }
    }

    ul {
      li {
        a:hover,
        &.is-current-active > a {
          background: $disques;
        }
      }
    }
  }
}


// ===============================================================================
// VIEWS
// ===============================================================================

//Couleurs familles
body .color {
  &.color-innoveyr,
  &.color-#{$color-menu-innoveyr} {
    color: $innoveyr;
    border-color: $innoveyr;

    .products-list--details {
      &:before {
        border-color: $innoveyr;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $innoveyr;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $innoveyr;
        }
      }
    }
  }

  &.color-solp, &.color-#{$color-menu-solp} {
    color: $solp;
    border-color: $solp;

    .products-list--details {
      &:before {
        border-color: $solp;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $solp;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $solp;
        }
      }
    }
  }

  &.color-sols, &.color-#{$color-menu-sols} {
    color: $sols;
    border-color: $sols;

    .products-list--details {
      &:before {
        border-color: $sols;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $sols;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $sols;
        }
      }
    }
  }

  &.color-hyg, &.color-#{$color-menu-hyg} {
    color: $hyg;
    border-color: $hyg;

    .products-list--details {
      &:before {
        border-color: $hyg;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $hyg;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $hyg;
        }
      }
    }
  }

  &.color-ali, &.color-#{$color-menu-ali} {
    color: $ali;
    border-color: $ali;

    .products-list--details {
      &:before {
        border-color: $ali;
      }
    }

    .menu__item {
      > a:hover {
        color: $black;
        background-color: $ali;
      }

      &.is-current-active {
        & > a {
          color: $black;
          font-weight: 600;
          background-color: $ali;
        }
      }
    }
  }

  &.color-linge, &.color-#{$color-menu-linge} {
    color: $linge;
    border-color: $linge;

    .products-list--details {
      &:before {
        border-color: $linge;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $linge;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $linge;
        }
      }
    }
  }

  &.color-pro, &.color-#{$color-menu-pro} {
    color: $pro;
    border-color: $pro;

    .products-list--details {
      &:before {
        border-color: $pro;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $pro;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $pro;
        }
      }
    }
  }

  &.color-orm, &.color-#{$color-menu-orm} {
    color: $orm;
    border-color: $orm;

    .products-list--details {
      &:before {
        border-color: $orm;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $orm;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $orm;
        }
      }
    }
  }

  &.color-ecocert, &.color-#{$color-menu-ecocert} {
    color: $ecocert;
    border-color: $ecocert;

    .products-list--details {
      &:before {
        border-color: $ecocert;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $ecocert;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $ecocert;
        }
      }
    }
  }

  &.color-ecolabel, &.color-#{$color-menu-ecolabel} {
    color: $ecoabel;
    border-color: $ecoabel;

    .products-list--details {
      &:before {
        border-color: $ecoabel;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $ecoabel;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $ecoabel;
        }
      }
    }
  }

  &.color-san, &.color-#{$color-menu-san} {
    color: $san;
    border-color: $san;

    .products-list--details {
      &:before {
        border-color: $san;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $san;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $san;
        }
      }
    }
  }

  &.color-dech, &.color-#{$color-menu-dech} {
    color: $dech;
    border-color: $dech;

    .products-list--details {
      &:before {
        border-color: $dech;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $dech;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $dech;
        }
      }
    }
  }

  &.color-pmat, &.color-#{$color-menu-pmat} {
    color: $pmat;
    border-color: $pmat;

    .products-list--details {
      &:before {
        border-color: $pmat;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $pmat;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $pmat;
        }
      }
    }
  }

  &.color-gmat, &.color-#{$color-menu-gmat} {
    color: $gmat;
    border-color: $gmat;

    .products-list--details {
      &:before {
        border-color: $gmat;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $gmat;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $gmat;
        }
      }
    }
  }

  &.color-epi, &.color-#{$color-menu-epi} {
    color: $epi;
    border-color: $epi;

    .products-list--details {
      &:before {
        border-color: $epi;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $epi;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $epi;
        }
      }
    }
  }

  &.color-reshot, &.color-#{$color-menu-reshot} {
    color: $reshot;
    border-color: $reshot;

    .products-list--details {
      &:before {
        border-color: $reshot;
      }
    }

    .menu__item {
      > a:hover {
        color: $black;
        background-color: $reshot;
      }

      &.is-current-active {
        & > a {
          color: $black;
          font-weight: 600;
          background-color: $reshot;
        }
      }
    }
  }

  &.color-autrmat, &.color-#{$color-menu-autrmat} {
    color: $autrmat;
    border-color: $autrmat;

    .products-list--details {
      &:before {
        border-color: $autrmat;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $autrmat;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $autrmat;
        }
      }
    }
  }

  &.color-dis, &.color-#{$color-menu-disques} {
    color: $disques;
    border-color: $disques;

    .products-list--details {
      &:before {
        border-color: $disques;
      }
    }

    .menu__item {
      > a:hover {
        background-color: $disques;
      }

      &.is-current-active {
        & > a {
          font-weight: 600;
          background-color: $disques;
        }
      }
    }
  }
}

.bgcolor {
  &.bgcolor-innoveyr, .bgcolor-innoveyr {
    background-color: $innoveyr;

    &.bgcolor-hover:hover {
      background-color: darken($innoveyr, 10%);
    }
  }

  &.bgcolor-solp, .bgcolor-solp {
    background-color: $solp;

    &.bgcolor-hover:hover {
      background-color: darken($solp, 10%);
    }
  }

  &.bgcolor-sols, .bgcolor-sols {
    background-color: $sols;

    &.bgcolor-hover:hover {
      background-color: darken($sols, 10%);
    }
  }

  &.bgcolor-hyg, .bgcolor-hyg {
    background-color: $hyg;

    &.bgcolor-hover:hover {
      background-color: darken($hyg, 10%);
    }
  }

  &.bgcolor-ali, .bgcolor-ali {
    background-color: $ali;

    &.bgcolor-hover:hover {
      background-color: darken($ali, 10%);
    }
  }

  &.bgcolor-linge, .bgcolor-linge {
    background-color: $linge;

    &.bgcolor-hover:hover {
      background-color: darken($linge, 10%);
    }
  }

  &.bgcolor-pro, .bgcolor-pro {
    background-color: $pro;

    &.bgcolor-hover:hover {
      background-color: darken($pro, 10%);
    }
  }

  &.bgcolor-orm, .bgcolor-orm {
    background-color: $orm;

    &.bgcolor-hover:hover {
      background-color: darken($orm, 10%);
    }
  }

  &.bgcolor-ecocert, .bgcolor-ecocert {
    background-color: $ecocert;

    &.bgcolor-hover:hover {
      background-color: darken($ecocert, 10%);
    }
  }

  &.bgcolor-ecolabel, .bgcolor-ecolabel {
    background-color: $ecoabel;

    &.bgcolor-hover:hover {
      background-color: darken($ecoabel, 10%);
    }
  }

  &.bgcolor-san, .bgcolor-san {
    background-color: $san;

    &.bgcolor-hover:hover {
      background-color: darken($san, 10%);
    }
  }

  &.bgcolor-dech, .bgcolor-dech {
    background-color: $dech;

    &.bgcolor-hover:hover {
      background-color: darken($dech, 10%);
    }
  }

  &.bgcolor-pmat, .bgcolor-pmat {
    background-color: $pmat;

    &.bgcolor-hover:hover {
      background-color: darken($pmat, 10%);
    }
  }

  &.bgcolor-gmat, .bgcolor-gmat {
    background-color: $gmat;

    &.bgcolor-hover:hover {
      background-color: darken($gmat, 10%);
    }
  }

  &.bgcolor-epi, .bgcolor-epi {
    background-color: $epi;

    &.bgcolor-hover:hover {
      background-color: darken($epi, 10%);
    }
  }

  &.bgcolor-reshot, .bgcolor-reshot {
    background-color: $reshot;

    &.bgcolor-hover:hover {
      background-color: darken($reshot, 10%);
    }
  }

  &.bgcolor-autrmat, .bgcolor-autrmat {
    background-color: $autrmat;

    &.bgcolor-hover:hover {
      background-color: darken($autrmat, 10%);
    }
  }

  &.bgcolor-dis, .bgcolor-dis {
    background-color: $disques;

    &.bgcolor-hover:hover {
      background-color: darken($disques, 10%);
    }
  }
}

// ===============================================================================
// OVERRIDES
// ===============================================================================
.footer-contact.footer-contact-bis {
  padding-top: 0;

  &:before {
    content: none;
  }
}

// ===============================================================================
// OVERRIDES
// ===============================================================================

//  PIT RGPD
// *************************************************************************
.rgpd-banner {
  background-color: $white;
  border-top: 1px solid lighten($primary-alt-color, 5%);

  a {
    color: lighten($primary-color, 15%);

    &:hover {
      color: $primary-color;
    }
  }
}

.rgpd-reveal {
  .reveal-title {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .reveal-content {
    padding: 2.25rem 2rem 0.75rem;
  }

  .reveal-footer {
    margin-top: 0;
    padding: 1.5rem 2rem;
    text-align: center;

    .button {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.webform-component--rgpd-texte {
  margin-top: 0;

  & > p {
    line-height: 1.5;
    font-size: 0.875rem;
    font-style: italic;
  }
}

/* Overrides jquery ui */
.ui-datepicker-calendar {

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-state-active,
  .ui-widget-content .ui-state-active {
    background: transparent none;
    border: 0 none;

    &:hover {
      background-color: $light-gray;
    }
  }

  .ui-state-default {
    display: block;
    text-align: center;
  }

  .ui-datepicker-current-day,
  .undefined:hover {
    background: $primary-color;

    .ui-state-active,
    .ui-state-default {
      color: $white;
      background-color: transparent;
    }
  }

  .ui-datepicker-unselectable {
    background-color: transparent !important;

    .ui-state-default {
      background-color: transparent !important;
      color: $black !important;

    }
  }
}

