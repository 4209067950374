/* Table des déclarations, V2 ! (http://bradfrost.com/blog/post/atomic-web-design/)
*******************************************************************************
  1- Overrides
      - override des librairies, plugins, ... (foundation, owl-carousel, select2 ...)
  2- Atoms
      - styles généraux d'éléments/balise HTML
      - utilitaires (.left, .right...)
      - éléments (bouton, input, list, etc)
  3- Ions
      - override des atoms spécifique au thème (bouton rouge, list avec puce carré, etc.)
  4- Molecules
      - assemblage d'atoms et d'ions (block d'actualités, fomulaire de contact, etc.)
  5- Organismes
      - assemblage de molécules, formant des parties de template (header, contenu principale, footer, grille d'actualités, etc.)
  6- Block
  7- Views
  8- Nodes
  9- Form
  10- Admin
      - overide admin drupal partie front
  11- Utilities
      - classe utilitaire
  12- Mediaqueries
*/

/* =============================================================================
Custom Shrink Classes
========================================================================== */
@each $breakpoint in $breakpoint-classes {
  @if $breakpoint == small {
    // Do nothing
  } @else {
    @include breakpoint($breakpoint) {
      .#{$breakpoint}-shrink {
        flex: 0 0 auto;
        max-width: 100%;
      }
    }
  }
}

// ===============================================================================
// OVERRIDES
// ===============================================================================
// Drupal Core
.close-button {
  right: 0.125rem;
  top: 0;
}

//Foundation
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.invisible {
  height: 0;
  width: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: -999rem;
  left: -999rem;
}

.switch {
  margin-bottom: 0;
}

.switch-input ~ .switch-paddle {
  border-radius: 0.3rem;
  background: $medium-gray;
  box-shadow: 0 0 0 1px rgba($white, 0.3);

  &:hover {
    background: darken($medium-gray, 12%);
  }

  &:before {
    content: 'off';
    position: absolute;
    top: 0.25rem;
    left: auto;
    right: 0.35rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: auto;
    margin: 0;
    line-height: 1;
    @include font-family(secondary, light);
    font-size: 0.8em;
    color: $white;
    text-transform: uppercase;
    background: none;
  }

  &:after {
    border-radius: 0.3rem;
  }
}

.switch-input:not(:checked) ~ .switch-paddle {
  &:hover {
    &:before {
      content: 'off';
      color: $white;
    }
  }
}

.switch-input:checked ~ .switch-paddle {
  &:hover {
    background: $primary-color;
  }

  &:before {
    content: 'on';
    left: 0.35rem;
    right: auto;
    background: transparent;
  }
}

// jQuery UI
.ui-datepicker {
  z-index: 9999 !important;
}

.ui-datepicker-header {
  position: relative;

  .ui-datepicker-prev,
  .ui-datepicker-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .ui-datepicker-prev {
    left: 0.25rem;
  }

  .ui-datepicker-next {
    right: 0.25rem;
  }

  .ui-datepicker-title {
    padding: 0.75rem 1.5rem;
    text-align: center;
  }
}

//Carousel
.owl-carousel {
  .owl-controls {
    position: absolute;
    bottom: rem-calc(10);
    right: rem-calc(10);
    padding: 0 rem-calc(25);

    .owl-prev {
      color: transparent !important;
      background: transparent !important;
      position: absolute;
      left: 0;
      bottom: rem-calc(-10);
      margin: 0;
      padding: 0;
      width: rem-calc(30);

      &:before {
        color: $white;
        opacity: 0.7;
        content: '\e909';
        font-family: 'icomoon';
        font-size: $font-size-xxstrong;

        &:hover {
          opacity: 1;
        }
      }
    }

    .owl-next {
      color: transparent !important;
      background: transparent !important;
      position: absolute;
      right: rem-calc(5);
      bottom: rem-calc(-10);
      margin: 0;
      padding: 0;
      width: rem-calc(30);

      &:before {
        color: $white;
        opacity: 0.7;
        content: '\e908';
        font-family: 'icomoon';
        font-size: $font-size-xxstrong;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.owl-theme {
  .owl-dots {
    .owl-dot span {
      background-color: $white !important;
      width: rem-calc(8);
      height: rem-calc(8);
      opacity: 0.7;
    }

    .owl-dot.active, .owl-dot:hover {
      span {
        opacity: 1;
      }
    }
  }
}

.product--images {
  .owl-stage {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
  }

  .owl-item {
    /*display: flex;
    align-items: center;
    height: 100%;
    min-height: 100%;*/
    position: relative;

    img {
      width: 100%
    }
  }

  .owl-controls {
    bottom: auto;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: rem-calc(-30);

    .owl-next {
      bottom: auto;
      top: 0;
      right: 0;
      background: rgba($black, 0.7) !important;
    }

    .owl-prev {
      bottom: auto;
      top: 0;
      left: 0;
      background: rgba($black, 0.7) !important;
    }
  }
}

//Lightbox
.reveal {
  .close-button {
    margin-top: rem-calc(10);
    padding: 0.9375rem 0.625rem;
    line-height: 0;
    font-family: sans-serif;
    font-size: $font-size-xxxstrong;
    border: 0 none;
  }

  & > .messages {
    margin: -1rem;
    padding: 2rem 2rem 2rem 3rem;
    background-position: 1rem 2rem;

    ul {
      list-style: none inside none;
    }
  }
}

//Filtres views
.views-exposed-widgets {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: auto;
  padding-top: 0.875rem;
  max-width: calc(#{$global-width} - #{map-get($grid-column-gutter, medium)});

  .views-exposed-widget {
    display: flex;
    align-items: center;

    label {
      margin-right: 0.6rem;
      margin-bottom: 1rem;
    }

    &:not(:last-child) {
      padding-right: 1.3rem;
    }

    .form-submit,
    .form-item,
    .form-actions {
      margin: 0;
    }
  }

  .views-submit-button:not(.views-exposed-widget) {
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
}

//Pagination
.item-list .pager {
  margin: rem-calc(20) 0;

  li {
    margin: 0;
    padding: 0;

    a {
      display: inline-block;
      padding: rem-calc(5);
    }
  }
}

//Accordéons
.accordion-item-fiches {
  // margin-top: rem-calc(20);
  margin-top: 1.5rem;

  .accordion-title {
    text-transform: uppercase;
    color: $primary-color;
    border: 0;
    border-bottom: 1px solid $medium-gray !important;
    padding-left: rem-calc(60);
    font-size: $font-size-small;
    padding-right: rem-calc(40);

    &:after {
      content: "";
      background: transparent url('#{$base-theme-basepath}assets/images/pictos/ft-fds.svg') top left no-repeat;
      background-size: contain;
      width: rem-calc(50);
      height: rem-calc(60);
      display: inline-block;
      position: absolute;
      left: 0;
      top: rem-calc(5);
    }

    &:before {
      content: '\e907';
      display: none;
      font-family: 'icomoon';
      font-size: $font-size-strong;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &.is-active {
    .accordion-title {
      &:before {
        content: '\e906';
      }
    }
  }
}

.accordion-content-bis {
  border: 0 none !important;
  padding: 0 rem-calc(15);

  [class^="icon-"], [class*=" icon-"] {
    /*font-size: $font-size-strong;*/
    margin-right: rem-calc(10);
  }
}

// Tooltpis
// ------
.has-tip {
  border: 0 none;
}

// SELECT 2
#form-society-products-import .select2-search__field {
  width: 240px !important;
}

.select2-container {
  margin: 0 0 1rem;
  width: auto !important;

  &.select2-selection--focus,
  .select2-selection--single,
  .selection,
  &:focus {
    outline: none;
  }

  .select2-selection--single {
    height: auto;
    padding: 0.25rem;
    line-height: 1.1;
    min-width: 13rem;
    background: $select-background;
    border-radius: $select-radius;
    border: $input-border;
    color: $input-color;
    font-size: $input-font-size;

    .select2-selection__rendered {
      color: $input-color;
    }

    .select2-selection__arrow {
      background: transparent;
      border: none;
      right: 0.3rem;
      top: 0.4rem;

      b {
        background-size: 80% !important;
        background-position: center;
      }
    }
  }
}

.select2-selection__clear {
  display: none;
}

.select2-drop-active {
  border: $input-border;
  color: $input-color;
  text-transform: uppercase;
  font-size: $input-font-size;
}

.select2-results {
  padding: 0;
  margin: 0;

  .select2-highlighted {
    background: transparentize($black, 0.8);
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: $primary-color;
}

//Sticky
.sticky.is-stuck {
  width: 100%;
}

// ===============================================================================
// ATOMS
// ===============================================================================
* {
  padding: 0;
  margin: 0;
}

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

// Elements
// --------

//Global inputs
[type='text'], [type='password'], [type='date'], [type='datetime'],
[type='datetime-local'], [type='month'], [type='week'], [type='email'],
[type='number'], [type='search'], [type='tel'], [type='time'], [type='url'],
[type='color'], textarea {
  padding: 0 rem-calc(15);
  height: rem-calc(40);

  &.input--qte {
    display: inline-block;
    width: rem-calc(70);
    margin: 0;
    padding: 0;
    text-align: center;
  }

  &.input--amount {
    display: inline-block;
    width: 8rem;
    margin: 0;
    padding: 0;
    text-align: center;
  }
}

textarea {
  padding: rem-calc(15);
}

[type='submit'], [type="button"], button {
  padding: rem-calc(15) rem-calc(10);
  font-size: $font-size-body;
  transition: all 0.3s;

  &#edit-reset {
    background: transparent;
    color: $primary-color;
    border-color: $primary-color;

    &:hover {
      background: $primary-color;
      color: $white;
    }
  }
}

input[disabled], input[disabled]:hover,
input:disabled, input:disabled:hover {
  background: $dark-gray !important;
}

select {
  height: rem-calc(50);
}

select.select2-like {
  height: auto;
  width: auto;
  min-width: 13rem;
  padding: 0.625rem 1.625rem 0.5625rem 0.75rem;
  line-height: 1.1;
}

// Input checkbox + radio
// -----------------------
input[type='radio'],
input[type='checkbox'] {
  display: none !important;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  & + label {
    cursor: pointer;
    position: relative;
    margin-left: 0;

    &:before {
      content: '';
      display: inline-block;
      margin-right: 0.5rem;
      width: rem-calc(20);
      height: rem-calc(20);
      line-height: rem-calc(20);
      vertical-align: text-bottom;
      text-align: center;
      font-family: 'icomoon';
      color: $white;
      font-size: 0.7rem;
    }
  }
}

// Checkbox Specific styles
input[type='checkbox'] {
  & + label:before {
    background-color: transparentize($primary-color, 0.5);
    border-radius: 0.1rem;
  }

  &:not(:checked) + label:hover:before {
    color: transparentize($primary-color, 0.2);
  }

  &:checked + label:before,
  &:not(:checked) + label:hover:before {
    content: "\ea10";
  }

  &:checked + label:before {
    background-color: $primary-color;
  }
}

input[type='radio'] {
  & + label:before {
    background-color: transparentize($primary-color, 0.3);
    border-radius: 50%;
    content: "\ea56";
  }

  &:not(:checked) + label:hover:before {
    background-color: transparentize($primary-color, 0.1);
  }

  &:checked + label:before {
    content: "\ea54";
    background-color: $primary-color;
  }
}

input[type='radio'].input--radio {
  & + label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0.5rem;
    line-height: 1.25;
    @include font-family(primary, regular);
    color: $black;
    text-transform: none;

    &:before {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.1875rem;
      color: $primary-color;
      border-radius: initial;
      background-color: transparent;
    }

    &:hover {
      color: $primary-color;
    }
  }

  &:checked + label {
    @include font-family(primary, bold);
    color: $primary-color;
  }
}

label {
  text-transform: uppercase;
  @include font-family(secondary, light);
  color: $dark-gray;
  font-size: $font-size-body !important;
}

button.menu {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  padding: 0 rem-calc(25)
}

input[type="submit"],
input[type="button"],
button,
.button {
  padding: $button-padding;
  font-size: $font-size-body;
  transition: all 0.3s;
  margin-bottom: 0;
  border: 1px solid $primary-color;

  &:hover {
    background: transparent;
    color: $primary-color;
  }
}

.button.invert {
  color: $primary-color;
  background: transparent;

  &:hover {
    color: $white;
    background: $primary-color;
  }
}

.button.small {
  padding-top: 0.4375rem;
  padding-bottom: 0.5rem;
}

.button.long {
  padding-left: 2.125rem;
  padding-right: 2.125rem;
}

.input-number-wrapper {
  position: relative;
}

.input-number-arrow {
  position: absolute;
  right: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(50% - 1px);
  width: rem-calc(16);
  padding-right: rem-calc(1);
  border-left: 1px solid $medium-gray;
  font-size: rem-calc(10);
  font-weight: 700;
  color: $primary-color;
  background: $white;

  &:hover {
    color: $white;
    background: $primary-color;
    cursor: pointer;
  }
}

.input-number-more {
  top: 1px;
}

.input-number-less {
  bottom: 1px;
}

// HTML balises
// *******************************************************************************
html,
body {
  min-height: 100%;
  font-size: 100%;
}

body {
  @include font-family(primary, regular);
  font-size: $font-size-body;
  color: $black-light;
  background: $white; /* Old browsers */
  background: -moz-linear-gradient(top, $white 0%, $off-white 20%, $medium-gray 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $white 0%, $off-white 20%, $medium-gray 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $white 0%, $off-white 20%, $medium-gray 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-color;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  text-rendering: optimizeLegibility;
  @include font-family(secondary, light);
}

h1, h2, h3 {
  font-size: $font-size-xxstrong;

  &.page-title {
    /*font-size: $font-size-xstrong;*/
  }

  &.page-title-main {
    /*font-size: $font-size-xstrong;*/
    margin: rem-calc(20) 0 rem-calc(30) 0;
    text-align: left;
    display: flex;
    align-items: center;
    /*text-transform: uppercase;*/
    /*&:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 15px 15px;
      border-color: transparent transparent transparent $primary-color;
      margin-right: 0.625rem;
      margin-left: rem-calc(-15);
    }*/
  }

  &.page-title-gamme {
    margin-left: rem-calc(10);
  }

  &.page-subtitle-underline {
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.875rem;
    padding: 0.375rem 0.25rem;
    font-size: 1.75rem;
    color: $secondary-color;
    border-bottom: 1px solid $medium-gray;

    &.big-margin-b {
      margin-bottom: 2rem;
    }
  }
}

//Pages 404
.page-node-4450 h1.page-title-main,
.page-node-4505 h1.page-title-main {
  display: none;
}

h3 {
  font-size: $font-size-xstrong;
}

h4 {
  font-size: $font-size-strong;
}

p {
  line-height: 1.3;
}

hr {
  &.clear {
    border: none;
  }
}

img {
  max-width: 100%;
  height: auto !important;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

img.zoomImg {
  max-width: 1000%;
}

a {
  color: $secondary-color;
  transition: all 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $tonic-color;
  }
}

ul {
}

iframe {
  max-width: 100%;
}

thead {
  th {
    font-size: $font-size-small;
    border-bottom: 0;
  }
}

tr.odd {
  background-color: $white;
}

body tr.even, body tr.odd {
  border-bottom: 1px solid $medium-gray;

  td.active {
    background: inherit;
  }
}

tbody th, tbody td {
  vertical-align: middle;
}

::-webkit-input-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

// ===============================================================================
// IONS
// ===============================================================================
.circle-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  line-height: 1;
  @include font-family(primary, medium);
  // @include font-family(primary, bold);
  font-size: 1.25rem;
  color: $primary-color;
  border: 0.1875rem solid $primary-color;
  border-radius: 50%;
}

// Global Loader
// --------------
body.is-global-loading {
  overflow: hidden;
}

.global-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $global-loader-color;
  background-color: $global-loader-background;
  z-index: 9999;
}

.global-loader,
.global-loader:before,
.global-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: global-loading 1.8s infinite ease-in-out;
}

.global-loader {
  color: $global-loader-color;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.global-loader:before {
  content: '';
  position: absolute;
  top: 0;
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.global-loader:after {
  content: '';
  position: absolute;
  top: 0;
  left: 3.5em;
}

@keyframes global-loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

// ===============================================================================
// MOLECULES
// ===============================================================================
#skip-link {
  display: none;
}

// User login
// -----
.block-user-login-form {
  a {
    text-align: right;
    font-style: italic;
    display: block;
    font-size: $font-size-small;
  }

  .form-actions {
    margin-bottom: 0;

    input[type="submit"] {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.block-user-login-redirects {
  margin-top: 1.75rem;
  padding-top: 1.125rem;
  border-top: 1px solid #cacaca;
}

.block-user-login-redirects-row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 2rem -1rem 0;
}

.block-user-login-redirect {
  flex: 0 0 50%;
  max-width: 50%;

  padding: 0 1rem;
  margin-bottom: 0.75rem;
}

.block-user-login-redirect-label {
  display: block;
  margin-bottom: 0.375rem;
}

// Pager
// -----
.item-list {
  width: 100%;

  .pager {
    margin-top: 4rem;
    font-size: $font-size-small;

    li:before {
      display: none;
    }

    .pager-item,
    .pager-current {
      position: relative;
      margin-right: 0.7rem;
      margin-left: 0.7rem;
      padding-left: 0;
      padding-right: 0;

      a {
        padding-right: 0.7rem;
        padding-left: 0.7rem;
        color: $dark-gray;

        &:hover {
          color: $primary-color;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: rem-calc(1);
        height: 100%;
        background-color: $primary-color;
        top: 0;
        right: -0.2rem;
        transform: rotate(20deg);
        opacity: 0.3;
      }

      &:before {
        content: '';
        position: absolute;
        width: rem-calc(1);
        height: 100%;
        background-color: $primary-color;
        top: 0;
        left: -0.2rem;
        transform: rotate(20deg);
        opacity: 0.3;
      }

      & ~ .pager-item {
        margin-left: 0;

        a {
          padding-left: 0;
        }

        &:before {
          display: none;
        }
      }

      &.first {
        &:before {
          display: none;
        }
      }
    }

    .pager-current {
      color: $primary-color;
      padding-right: 0.7rem;
      padding-left: 0;
      margin-left: 0;

      &.first {
        padding-left: 0.7rem;
      }
    }

    .pager-ellipsis {
      margin-left: 0;
    }

    .pager-previous a,
    .pager-first a,
    .pager-next a,
    .pager-last a {
      color: $dark-gray;
    }

    .pager-previous,
    .pager-first {
      margin-left: 0;
      margin-right: 0.6rem;

      a:before {
        font-family: 'icomoon';
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.2rem;
        transition: transform 0.3s;
      }
    }

    .pager-next,
    .pager-last {
      margin-left: 0.6rem;

      a:after {
        font-family: 'icomoon';
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.2rem;
        transition: transform 0.3s;
      }

      a:hover:after {
        transform: translateX(0.3rem);
      }
    }

    .pager-next {
      a:after {
        content: '\e908';
      }
    }

    .pager-previous {
      a:before {
        content: '\e908';
        transform: rotate(-180deg);
      }

      a:hover:before {
        transform: translateX(-0.3rem) rotate(-180deg);
      }
    }

    .pager-last {
      a:after {
        content: '\e90b';
      }
    }

    .pager-first {
      a:before {
        content: '\e90b';
        transform: rotate(-180deg);
      }

      a:hover:before {
        transform: translateX(-0.3rem) rotate(-180deg);
      }
    }
  }
}

// REVEAL MODAL
// ------------------
// .reveal-modal {]

.reveal-modal--header {
  margin-bottom: 2.25rem;
}

// .reveal-modal--content {}

.reveal-modal--extras {
  margin-top: 1.5rem;
}

.reveal-modal--footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  // margin-top: 2.5rem;
  margin-top: 2.625rem;

  .button:not(:last-child) {
    margin-right: 0.625rem;
  }
}

// ORDERS HISTORY
// ---------------
.orders-table-row {
  &.declined {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      width: 0.125rem;
      background: $alert-color;
    }

    .order-declined {
      color: $alert-color;
      background: rgba($alert-color, 0.1);
      @include font-family(primary, medium);
      padding: 0.1875rem 0.5rem 0.0625rem;
      border-radius: 0.25rem;
      font-size: 0.6875rem;
      text-transform: uppercase;
    }
  }

  &.awaiting_budget,
  &.awaiting_validation {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      width: 0.125rem;
      background: $secondary-color;
    }

    .order-awaiting {
      color: $secondary-color;
      background: rgba($secondary-color, 0.1);
      @include font-family(primary, medium);
      padding: 0.1875rem 0.5rem 0.0625rem;
      border-radius: 0.25rem;
      font-size: 0.6875rem;
      text-transform: uppercase;
    }
  }
}

// ORDERS TO VALIDATE
// ------------------
.orders-to-validate-group {
  margin-bottom: 1.25rem;
}

.order-to-validate {
  margin-bottom: 2.5rem;
}

// BUDGETS HISTORY
// ---------------
.budgets-history-year {
  margin-left: 0;
  list-style: none;
}

.budgets-history-month {
  margin-top: 0.625rem;

  &.is-open {
    .budgets-history-month--header:after {
      transform: translate(50%, -50%) rotate(90deg);
    }
  }

  &.is-closed {
    .budgets-history-month--header:after {
      transform: translate(50%, -50%) rotate(0deg);
    }
  }
}

.budgets-history-month--header {
  position: relative;
  // padding: 0.375rem 1.75rem 0.375rem 0.25rem;
  padding: 0.5rem 1.875rem 0.375rem 0.25rem;
  border-bottom: 1px solid $primary-color;

  &:hover {
    cursor: pointer;
    background-color: $off-white;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    // right: calc(1.75rem / 2);
    right: calc(2rem / 2);
    width: 1.25rem;
    height: 1.25rem;
    background: transparent url('#{$base-theme-basepath}assets/images/pictos/toggle-arrow-blue.svg') center no-repeat;
    background-size: 0.75rem 1.25rem;
  }
}

.budgets-history-month--title {
  line-height: 1.125;
  @include font-family(secondary, bold);
  font-size: 1.25rem;
  color: $primary-color;
}

.budgets-history-month--budget {
  padding: 0 0.5rem;
  line-height: 1.25;
  @include font-family(secondary, bold);
  font-size: 1.125rem;
  color: $primary-color;

  &.is-positive {
    color: $success-color;
  }

  &.is-negative {
    color: $alert-color;
  }

  // &:not(.budgets-history-month--no-budget){
  //   width: 13.25rem;
  // }
}

.budgets-history-month--amount {
  display: inline-block;
  text-align: right;
}

.budgets-history-month--content {
  // margin-bottom: 1.875rem;
  margin-bottom: 1.75rem;
}

.budgets-history-month--orders .views-table {
  margin: 0;
}

.budgets-history-month--total-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // margin-right: 1.25rem;
  margin-right: 1rem;
  padding: 0.375rem 0 0.25rem;
  line-height: 1.25;
  @include font-family(primary, bold);
}

.budgets-history-month--total-amount {
  padding: 0.25rem 1.125rem;
  text-align: center;
  @include font-family(primary, bold);
  font-size: 1.125rem;
  color: $white;
  background-color: $xdark-gray;
}

.budgets-history-month--no-orders {
  // padding: 1.25rem 1rem 1.375rem;
  padding: 1.375rem 1rem;
  line-height: 1.125;
  @include font-family(secondary, bold);
  font-size: 1.25rem;
  color: $primary-color;
  text-align: center;
  border-bottom: 1px solid $medium-gray;
  background-color: $white;
}

// BUDGETS MANAGEMENT
// ------------------
.budgets-mgmt-add--top {
  position: absolute;
  right: 0;
  top: -0.875rem;
}

.budgets-mgmt-add--bottom {
  float: right;
}

.budgets-mgmt-total {
  position: relative;
  top: -0.1875rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 1.75rem;
  margin-left: 0.5rem;
  padding-left: 0.875rem;
  line-height: 1.125;
  @include font-family(secondary, bold);
  font-size: 1.125rem;
  color: $primary-color;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 1.75rem;
    width: 0.25rem;
    background-color: $primary-color;
    transform: translateY(-50%);
  }
}

.budgets-mgmt-users {
  margin: 0 0 2.75rem;
  list-style: none;
}

.budgets-mgmt-user {
  margin-bottom: 2rem;
}

.budgets-mgmt-user--header {
  padding: 0 0.25rem;
}

.budgets-mgmt-user--name {
  padding: 0.25rem 0 0.1875rem;
  line-height: 1.125;
  @include font-family(secondary, bold);
  font-size: 1.25rem;
  color: $primary-color;
}

.budgets-mgmt-user--remove-all {
  display: block;
  padding: 0.375rem;
  margin: 0 0.5rem;
  line-height: 1;
  color: $black;

  &:hover {
    color: $tonic-color;
  }
}

.budgets-mgmt-user--content {
  margin-top: 0.125rem;
}

.budgets-mgmt-user-table {
  margin: 0;
  border: 1px solid $primary-color;
  border-top-width: 0.125rem;

  thead, tbody {
    border: 0;
  }

  tr.odd:last-child, tr.even:last-child {
    border-bottom: 0;
  }
}

// .budgets-mgmt-user-table--global {
//   width: calc(100% - (10.25rem + 3rem));
// }

.budgets-mgmt-user-table--global-text {
  position: relative;
  top: -1px;
  font-size: 0.875rem;
  font-weight: 700;
}

.budgets-mgmt-user-table--global-amount {
  padding: 0 0.125rem;
  font-size: 1.125rem;
}

.budgets-mgmt-user-table--address {
  width: calc((100% - (10.25rem + 3rem)) * 0.66);
  font-size: 0.875rem;
  line-height: 1.4375;

  .addressfield-container-inline {
    // &.name-block,
    &.locality-block {
      float: left;
      margin-right: 0.4375em;
    }
  }
}

.budgets-mgmt-user-table--address-name {
  @include font-family(primary, bold);
  color: $primary-color;
  font-size: 1rem;
}

.budgets-mgmt-user-table--amount {
  width: calc((100% - (10.25rem + 3rem)) * 0.33);
  font-size: 1.125rem;
  text-align: center;
}

.budgets-mgmt-user-table--edit {
  text-align: center;
  width: 10.25rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.budgets-mgmt-user-table--remove {
  width: 3rem;
}

.budgets-mgmt-user--remove-address {
  display: block;
  padding: 0.375rem;
  line-height: 1;
  color: $black;

  &:hover {
    color: $tonic-color;
  }
}

.budgets-mgmt-user--add-address-line {
  border-top: 1px solid $primary-color;
  position: relative;
  top: -1px;
}

.budgets-mgmt-user--add-address {
  position: relative;
  top: -1px;
}

// BUDGETS MANAGEMENT MODAL
// ------------------------
.warning-message-wrapper {
  text-align: center;

  .warning-message {
    display: inline-block;
    color: darken($warning-color, 5%);
    background: rgba($warning-color, 0.1);
    padding: 0.4375rem 1.25rem 0.5rem;
    border-radius: 0.5rem;
    text-align: left;
    font-size: 0.875rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .warning-list {
    margin-bottom: 0;
  }
}

.budgets-mgmt-modal-step {
  margin-bottom: 2rem;
}

.budgets-mgmt-modal-step--header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.75rem;

  .circle-number {
    margin-right: 0.5rem;
  }
}

.budgets-mgmt-modal-step--title {
  @include font-family(primary, bold);
}

.budgets-mgmt-modal-step--content {
  margin-left: 2.375rem;
}

.budgets-mgmt-modal-step--error {
  margin-top: 0.25rem;
  font-size: 0.8125rem;
  color: $alert-color;
}

.budgets-mgmt-modal--users {
  margin: 0;
}

.budgets-mgmt-modal--amount-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:after {
    content: '€';
    position: relative;
    top: -1px;
    @include font-family(primary, medium);
    font-size: 1.3125rem;
    margin-left: 0.625rem;
  }
}

.budgets-mgmt-modal--addresses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 14.125rem;
  overflow-y: auto;
}

.budgets-mgmt-modal-address--wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  // padding-right: 0.625rem;
  padding-right: 0.5rem;

  &:nth-child(n+3) {
    // margin-top: 0.625rem;
    margin-top: 0.5rem;
  }
}

.budgets-mgmt-modal-address {
  height: 100%;
  padding: 0.625rem 0.875rem;
  border: 0.125rem solid $xlight-gray;
  background: $xlight-gray;

  &:hover {
    cursor: pointer;
    background: darken($xlight-gray, 10%);
    border-color: darken($xlight-gray, 10%);
  }

  &.is-checked {
    border-color: $primary-color;

    .budgets-mgmt-modal-address--name {
      // @include font-family(primary,bold);
      color: $primary-color;
    }

    &:hover {
      border-color: $primary-color;
    }
  }
}

.budgets-mgmt-modal-address--name {
  @include font-family(primary, bold);
  font-size: 1rem;
}

.budgets-mgmt-modal-address--address {
  font-size: 0.875rem;
  // .name-block {
  //   display: none;
  // }
  // .street-block {
  //   .thoroughfare, .premise {
  //     display: inline;
  //   }
  // }
  .country {
    display: none;
  }
}

// ===============================================================================
// ORGANISMES
// ===============================================================================

.off-canvas-wrapper {
  z-index: 3;

  .position-left.is-open, .position-right.is-open {
    width: 100%;
  }
}

// HEADER
// ------
.header {
  background: $white;
  border-bottom: 1px solid $primary-alt-color;

  h1 {
    margin: 0;
  }
}

.header-top {
  background: $primary-alt-color;
  color: $white;
  padding: rem-calc(5) 0;
  @include font-family(secondary, regular);
  z-index: 2;

  p {
    color: $white;
    margin: 0;
  }

  a {
    color: $white;

    &:hover {
      color: $tonic-color;
    }
  }

}

.header--phone {
  font-size: $font-size-medium;
}

.header--bloc-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;

  .contextual-links-trigger {
    font-size: 0;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      //padding: 0 rem-calc(20) 0 0;

      &:after {
        display: inline-block;
        height: rem-calc(15);
        border-right: 1px solid $medium-gray;
        content: '';
        font-size: $font-size-body;
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translateY(-50%);
      }
    }

    .header--groupe {
      line-height: 1;

      .icon-web {
        font-size: 1.125rem;
        vertical-align: text-top;
      }
    }

    .header--connexion {
      line-height: 1;
    }
  }

  a:not(.contextual-links-trigger),
  .a-like {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    text-transform: uppercase;
    //line-height: 1.3;
    @include font-family(secondary, regular);
    letter-spacing: 0.0625rem;
    font-size: 0.875rem;
    padding: 0.3125rem 0.5125rem;

    [class^="icon-"], [class*=" icon-"] {
      margin-right: rem-calc(5);
      margin-bottom: rem-calc(4);
    }

    &.header--ftfds {
      font-size: $font-size-xsmall;
      position: relative;
      text-align: left;
      padding-left: 0.8rem;
      padding-right: 3.3rem;
      line-height: 1.3;
      text-transform: uppercase;

      &:after {
        border: 0 none;
        background: transparent url('#{$base-theme-basepath}assets/images/pictos/ft-fds.svg') center no-repeat;
        width: 2.5rem;
        height: 3.2rem;
        position: absolute;
        right: 0;
        top: rem-calc(15);
        display: inline-block;
        content: '';
        margin-left: rem-calc(20);
        font-size: 1.125rem;
      }
    }
  }
}

.header--user-cart--full {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: $tonic-color;
  position: absolute;
  right: 4px;
  top: 0;
}

.header-content {
  justify-content: space-between;
  min-height: 6rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  .header-slogan {
    position: relative;
    color: $primary-alt-color;
    text-transform: uppercase;
    margin-left: 1.6rem;
    @include font-family(secondary, regular);
    letter-spacing: 0.0625rem;

    &:before {
      content: '';
      position: absolute;
      height: 50%;
      border-left: 1px solid $medium-gray;
      left: -1rem;
      top: 25%;
    }
  }
}

.header--bloc-left {
  & > .row {
    & > .column {
      padding-right: 0.6rem;

      &.header-social--mail {
        position: relative;
        margin-right: 0.7rem;
        padding-left: 0.4rem;
        margin-bottom: -0.2rem;
        padding-right: 0.65rem;

        &.with-separator:after {
          content: '';
          position: absolute;
          height: 50%;
          width: rem-calc(1);
          right: 0;
          top: 20%;
          background: transparentize($white, 0.2);
        }
      }
    }
  }

  .icon-facebook {
    position: relative;
    left: rem-calc(-1);
  }

  .icon-twitter {
    position: relative;
    right: rem-calc(-1);

  }
}


.header-separator {
  margin-left: 0.6rem;
}

// COMMERCIAL
.header-commercial {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 0.75rem;

  &.header-commercial-catalogue {
    padding: .5rem 1rem !important;
    margin-left: 1rem;
    border-left: 1px solid $light-gray;
  }
}

.header-commercial-icon {
  width: 1.875rem;
  height: 2.375rem;
  margin-right: 0.5rem;
  text-align: center;
  font-size: rem-calc(38);
  color: $tonic-color;
}

.header-commercial-texts {
  position: relative;
  top: 0.125rem;
  color: $tonic-color;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &:hover,
  &:focus,
  &:active {
    color: $primary-color;

    .header-commercial-plus {
      border-color: $primary-color;
    }
  }
}

.header-commercial-label {
  display: block;
  margin-bottom: 0.1875rem;
  line-height: 1;
  text-transform: uppercase;
  @include font-family(secondary, regular);
  font-size: 0.75rem;
}

.header-commercial-name {
  display: block;
  line-height: 1;
  text-transform: uppercase;
  @include font-family(secondary, bold);
  font-size: 1rem;
}

.header-commercial-plus {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.375rem;
  border: 1px solid $tonic-color;
  transition: border 0.3s;

  &:after {
    content: '+';
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: 0;
    font-size: 0.875rem;
    transform: translate(-50%, -50%);
  }
}

.header-commercial-return {
  color: $tonic-color;

  .header-commercial-label {
    margin-bottom: .25rem;
  }

  .masquerade-button {
    color: $tonic-color;
    border-color: $tonic-color;

    &:hover {
      color: $white;
      background-color: $tonic-color;
    }
  }
}

// MAIN
.main {
  padding-top: 3.6rem;
}

// MENU
.header-content .main-menu {
  & > .menu-item {
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      display: none;
    }

    & > .menu-link {
      &.is-current-active,
      &:hover {
        color: $secondary-color;

        &:after {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 0.6rem 0.6rem 0 0.6rem;
          border-color: $secondary-color transparent transparent transparent;
          top: calc(100% + 0.5rem);
          left: calc(50% - 0.6rem);
        }
      }

      &:not(.is-current-active):hover {
        &:after {
          animation: translate 0.3s;
        }
      }
    }

    &:first-child {
      width: 2rem;
      border-left: none;

      & > .menu-link {
        height: rem-calc(35);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:before {
          content: "\e914";
          @include font-family(icomoon, regular);
          font-size: $font-size-strong;
          display: block;
          padding-top: 2rem;
          padding-bottom: 1rem;
        }
      }
    }
  }
}

.main-menu {
  align-items: center;
  justify-content: flex-end;
  font-size: rem-calc(17);

  & > .menu-item {
    border-left: 1px solid $medium-gray;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;

    & > .menu-link {
      display: block;
      position: relative;
      text-transform: uppercase;
      padding: 0.3rem 0.6rem;
      color: $primary-color;
    }

    &:nth-child(2) {
      border-left: none;
    }

    .sub-menu {
      display: none;
    }
  }
}

.header-internal {
  background: $white;
}

// Switch des gammes de produits
// ------
.menu--switch-products {
  border: 0;
  width: 100%;
  padding: rem-calc(22) 0 rem-calc(15) 0;

  .content {
    h2 {
      @include font-family(secondary, light);
      margin: rem-calc(5) 0 rem-calc(14);
      line-height: 1.1;
      font-size: $font-size-xxstrong;
      color: $primary-color;
      padding: 0;

      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 0 15px 15px;
        border-color: transparent transparent transparent $primary-color;
        margin-left: rem-calc(-2);
        margin-right: rem-calc(10);
      }
    }

    a {
      @include font-family(secondary, regular);
      color: $primary-color;
      background-color: $white;

      &:hover {
        background-color: $quatrary-color;
      }
    }

    a {
      margin-top: rem-calc(5);
      padding: rem-calc(5) rem-calc(18) rem-calc(7);
      @include font-family(secondary, light);
      font-size: $font-size-xmedium;
      color: $white;
      background-color: $primary-color;

      &:hover {
        background-color: $primary-alt-color;
      }
    }
  }
}

.main-menu--mobile {
  display: none;
}

//Menus produits des games -> Vertical
.menu-gammes {
  background: $white;
  position: relative;
  z-index: 2;
  display: block;
  border-bottom: 2px solid $medium-gray;

  &.menu-gammes-consommables {
    ul {
      ul {
        top: rem-calc(58);

        ul {
          top: 0;
        }
      }
    }
  }

  ul {
    list-style: none inside none;
    padding: 0;
    margin: 0;
    border-top: 1px solid $medium-gray;
    justify-content: safe center;
    flex-wrap: nowrap;
    align-items: stretch;


    > li {
      flex-shrink: 1;
      flex-grow: 1;
      text-align: center;
      min-width: rem-calc(100);
      position: relative;

      &:not(:last-child) {
        border-right: 1px dotted $medium-gray;
      }

      > a {
        padding: rem-calc(15) rem-calc(13);
        color: $black;
        font-size: $font-size-small;
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &:after {
          content: '';
          display: none;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 7px 0 7px;
          border-color: $white transparent transparent transparent;
          position: absolute;
          bottom: rem-calc(-7);
          right: 50%;
          margin-right: rem-calc(-4);
          z-index: 4;
        }

        &:before {
          content: '';
          display: none;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 7px 0 7px;
          border-color: $white transparent transparent transparent;
          position: absolute;
          bottom: rem-calc(-8);
          right: 50%;
          margin-right: rem-calc(-4);
          z-index: 3;
        }
      }

      // Sous-menu
      > ul {
        display: none;
        position: absolute;
        left: 0;
        top: rem-calc(45);
        overflow: hidden;
        width: 15rem;
        z-index: 2;
        flex-direction: column;
        padding: 0;
        margin: 0;
        background: $white;
        border: 3px solid $xlight-gray;
        flex-wrap: nowrap;
        justify-content: flex-start;

        &.is-hover {
          width: 30rem;

          > li {
            width: 50%;
          }
        }

        > li {
          width: 100%;
          flex-grow: 0;
          position: initial;

          &:not(:last-child) {
            border-right-width: 0;
          }

          &:hover {
            background: transparent;

            > a {
              background: transparent;
              color: $white;

              &:before {
                display: none;
              }
            }
          }

          > a {
            display: block;
            height: auto;
            padding: rem-calc(12) rem-calc(30);
            color: $black;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              right: 1.5rem;
              top: 1.8rem;
              transform: rotate(-90deg);
              display: block;

              border: 0 none;
              margin: 0;
              height: 0;

              color: $medium-gray;
            }

            &:hover {
              color: $white;

              &:after {
                color: $white;
              }
            }

            &:before {
              display: none !important;
            }
          }

          &.is-expanded {
            > a:after {
              font-family: 'icomoon';
              content: "\e907";
            }
          }

          // Ss-ss-menu
          > ul {
            position: absolute;
            width: 50%;
            top: 0;
            left: 14.65rem;
            display: none;
            flex-direction: column;
            flex-wrap: nowrap;
            background: transparent;
            border: 0 none;
            border-left: 3px solid $xlight-gray;

            &:after {
              display: none;
            }

            > li {
              width: 100%;

              > a {
                &:after {
                  display: none !important;
                }
              }
            }
          }
        }
      }

      // Exception des 4 derniers pour les coller à partir de la droite et non de la gauche.
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        > ul {
          left: auto;
          right: 0;
          align-items: flex-end;

          & > li {
            &.is-expanded > a:after {
              transform: rotate(90deg);
              right: auto;
              left: 1.5rem;
              top: 0.75rem;
            }

            & > ul {
              left: 3px;
              border-right: 3px solid $xlight-gray;
              border-left-width: 0;
            }
          }
        }
      }


      &:hover /*, &.isopensubmenu*/
      {
        > a {
          color: $white;

          &:after {
            display: inline-block;
          }

          &:before {
            display: inline-block;
          }
        }

        > ul {
          display: flex;

          &:hover > ul {
            display: block;
          }
        }
      }

      &:hover > ul {
        display: flex;

        &:hover {
          > ul {
            display: block;
          }
        }
      }


      &.is-current-active {
        border-bottom: 0;

        > a {
          @include font-family(primary, bold);
          color: $white;
          position: relative;
        }

        > ul > li {
          > a:hover {
            // color: $black;
            color: $white;
          }

          &.is-current-active > a {
            color: $white;
          }
        }
      }
    }
  }
}

//SS menu gamme horizontal
.submenu-container {
}

.sous-menu-gammes {
  .content {
    position: relative;
    height: rem-calc(48);
    background: $medium-gray;
  }

  .menu-block-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .submenu-container > ul > li:not(:last-child) {
    border-right: 1px dotted $off-white;
  }

  ul {
    list-style: none inside none;
    padding: 0;
    margin: 0;
    justify-content: safe center;
    flex-wrap: nowrap;
    align-items: stretch;
    display: flex;
    background: $medium-gray;

    > li {
      flex-shrink: 1;
      flex-grow: 1;
      text-align: center;
      width: 20%;

      > a {
        padding: rem-calc(14) rem-calc(5) rem-calc(18);
        color: $black;
        font-size: $font-size-small;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &:hover {
          color: $white;
        }

        &:after {
          content: '';
          border: 0 none;
          visibility: hidden;
          margin: rem-calc(2) auto 0 auto;
          position: relative;
          bottom: 0;
          right: 5px;
          text-align: center;
          display: block !important;
          height: 0;
        }
      }

      &.is-expanded {
        > a {
          padding-bottom: rem-calc(5);

          &:after {
            visibility: visible;
            font-family: 'icomoon';
            content: "\e907";
            margin-top: rem-calc(5);
            height: rem-calc(10);
          }
        }
      }

      &.is-current-active {
        > a {
          color: $white;
          font-weight: 600;
        }
      }

      &:last-child {
        > a {
          border-right: 0;
        }
      }

      &:hover {
        > a {
          color: $white;
        }
      }

      > ul {
        display: none;
        width: 100%;
        z-index: 2;
        flex-direction: column;
        padding: 0;
        align-items: left;
        margin: 0;
        // background: $off-white;
        background: $medium-gray;
        justify-content: flex-start;
        border-bottom: 3px solid;
        flex-wrap: nowrap;

        > li {
          width: 100%;

          &:hover {
            background: transparent;

            > a {
              color: $white;
              background: transparent;
            }
          }

          > a {
            display: block;
            color: $black;
            padding: rem-calc(15) rem-calc(5) rem-calc(15) rem-calc(5);

            &:hover {
              color: $white;
            }

            &:after {
              display: none !important;
            }
          }

          &.is-expanded.is-active-trail {
            ul {
              display: flex;
            }
          }
        }
      }

      &:last-child {
        border-right: 0;
      }

      &:hover {
        > ul {
          // position: absolute;
          display: flex;

          &:hover > ul {
            display: block;
          }
        }
      }
    }
  }
}

.submenu-openclose {
  background: $medium-gray;
  text-align: center;
  width: 100%;
  height: $font-size-body;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    font-family: 'icomoon';
    content: "\e906";
    color: $black;
    line-height: $font-size-body;
    font-size: $font-size-strong;
  }

  &:hover {
    background: $medium-gray;
  }

  &.closed {
    &:before {
      content: "\e907";
    }
  }
}

.menu--hover {
  position: absolute;
  // top: -1rem;
  top: -0.125rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.5);
  z-index: 1;
  display: none;
}

// Contenu
// ------

.main--breadcrumb {
  .breadcrumbs {
    // padding-top: rem-calc(20);
    margin: 0.125rem 0 1.125rem 0;

    li {
      text-transform: none;
      font-size: $font-size-small;
      color: $dark-gray;

      &:after {
        content: '>';
        color: $medium-gray;
      }

      &:last-child {
        &:after {
          content: '';
        }
      }

      a {
        color: $black;

        &:hover {
          color: $tonic-color;
        }
      }

      &.current {
        @include font-family(primary, bold);
      }
    }
  }
}

// .page-taxonomy {
//   .main--breadcrumb {
//     margin-left: rem-calc(10);
//   }
// }

.main {
  padding-top: rem-calc(40);
  margin-bottom: rem-calc(20);

  &.main--internal {
    background: $white;
    box-shadow: 0px 0px 13px $medium-gray;
    margin-bottom: 0;
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(70);
    position: relative;
    border-top: 1px solid $medium-gray;
  }

  .region {
    width: 100%;
  }
}

// FOOTER
// ------
.footer {
  background: scale-color($primary-alt-color, $lightness: $footer-lightness);
  color: $white;
  padding-top: rem-calc(40);
  position: relative;
}

.footer-contact {
  .block-title {
    text-transform: uppercase;
    color: $white;
    font-size: $font-size-xmedium;
    display: flex;
    align-items: center;
    margin: 0 0 0.6rem -0.9rem;
    padding: 0;

    &:before {
      content: "\e90d";
      font-family: 'icomoon';
      color: $tonic-color;
      font-size: $font-size-xxstrong;
    }
  }

  .content {
    display: flex;
    font-size: $font-size-small;

    p {
      padding-bottom: rem-calc(15);
      width: 40%;
      margin: 0 5% rem-calc(10) 0;
      @include font-family(primary, light);

      strong {
        text-transform: uppercase;
      }
    }
  }

  &.footer-contact-bis {
    padding-top: rem-calc(20);

    &:after {
      display: none;
    }

    &:before {
      content: '';
      width: rem-calc(100);
      border-top: 1px solid $white;
      display: block;
      width: 20%;
      margin: rem-calc(-20) 0 rem-calc(20) 45%;
    }

    p {
      a.footer--contact-link {
        display: flex;
        align-items: center;
        padding: 0 rem-calc(60) rem-calc(5) rem-calc(60);
        margin: rem-calc(5) 0;
        background: $footer-button-color;
        color: $white;

        @include breakpoint(large down) {
          padding-right: 1.75rem;
          padding-left: 1.75rem;
        }


        &:before {
          content: "\e905";
          font-family: 'icomoon';
          display: inline-block;
          margin-right: 0.6rem;
          font-size: $font-size-xstrong;
          transition: transform 0.2s ease-in-out;
        }

        &:hover {
          background: $primary-alt-color;

          &:before {
            transform: translateX(-0.3rem);
          }
        }

        &:nth-child(2) {
          &:before {
            content: '';
            background: transparent url(../images/pictos/picto-offres.png) center no-repeat;
            width: 1.875rem;
            height: 1.875rem;
            background-size: contain;
          }
        }
      }
    }
  }
}

.footer-slogan {
  margin-left: 1.6rem;
  position: relative;
  line-height: 1.2;

  &:before {
    content: '';
    position: absolute;
    height: 66%;
    border-left: 1px solid $white;
    left: -1rem;
    top: 17%;
  }
}

.footer-links {
  .content {
    p {
      margin-bottom: 1%;

      a {
        display: flex;
        padding: 0 rem-calc(60) rem-calc(5) rem-calc(60);
        margin: rem-calc(5) 0;
        background: $footer-button-color;
        color: $white;
        min-height: 3.3rem;
        align-items: center;
        position: relative;

        &:hover {
          background: $primary-alt-color;

          &:before {
            transform: translateX(-0.3rem);
          }
        }
      }

      &:not(:last-child) a:before {
        content: "\e90c";
        font-family: 'icomoon';
        position: absolute;
        left: 1rem;
        top: 25%;
        font-size: $font-size-strong;
        transition: transform 0.2s ease-in-out;
      }
    }
  }
}

.footer-middle {
  background: $primary-alt-color;

  .footer-middle--sitemap-toggle {
    text-transform: uppercase;
    color: $off-white;
    font-size: $font-size-xmedium;
    @include font-family(secondary, light);
    padding-top: 1.3rem;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $white;
    }

    &:after {
      content: '';
      display: inline-block;
      margin-left: 0.7rem;
      border-style: solid;
      border-width: 0.3rem 0 0.3rem 0.4rem;
      border-color: transparent transparent transparent $off-white;
      transition: all 0.4s;
    }

    &.opened {
      &:after {
        border-width: 0.4rem 0.3rem 0 0.3rem;
        border-color: $off-white transparent transparent transparent;
        transition: all 0.4s;
      }
    }

    &:hover {
      &:after {
        transform: translateX(0.3rem);
      }
    }
  }

  .footer-middle--sitemap {
    width: 100%;
    display: none;

    .block {
      .block-title {
        padding: 0 0.6rem;
        text-transform: uppercase;
        color: $off-white;
        font-size: rem-calc(17);
        @include font-family(secondary, regular);
        margin-bottom: 0;
        text-align: left;

        &:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 6px;
          border-color: transparent transparent transparent $white;
          margin-right: 0.625rem;
          margin-left: 0;
        }
      }

      .submenu-container {
        display: block;
        margin-bottom: rem-calc(20);
        padding-bottom: 0;
        border-bottom: 1px solid $primary-color;
      }

      .content {
        ul {
          list-style: none inside none;
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          margin: rem-calc(10) 0 rem-calc(10) rem-calc(15);

          li {
            list-style: none inside none;
            margin: 0;
            padding: 0;

            a {
              display: inline-block;
              padding: rem-calc(5) rem-calc(10) rem-calc(0) 0;
              background: transparent;
              color: $white;
              font-size: $font-size-small;

              &:hover {
                color: $tonic-color;
              }
            }

            &:not(:last-child) a:after {
              display: inline-block;
              content: '';
              border-right: 1px solid $medium-gray;
              width: 1px;
              height: rem-calc(10);
              margin-left: rem-calc(10);
            }
          }
        }

        .submenu-openclose {
          display: none;
        }

        p {
          width: 100%;
          text-align: right;

          a {
            font-style: italic;
          }
        }
      }
    }
  }
}

.footer-bottom {
  background: $primary-alt-color;
  padding-bottom: 1rem;
  padding-top: 0.6rem;

  .footer-bottom--agency {
    @include font-family(secondary, light);
    color: $white;
    font-size: $font-size-small;

    a {
      &:before {
        background: transparent url(#{$base-theme-basepath}assets/images/logo-agency.svg) center no-repeat;
        background-size: contain;
        content: '';
        width: rem-calc(90);
        height: rem-calc(25);
        display: inline-block;
        position: relative;
        top: rem-calc(10);
      }
    }
  }

  .footer-links {
    .content {
      ul {
        margin: 0;
        list-style: none inside none;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
          list-style: none inside none;

          a {
            color: $white;
            @include font-family(secondary, light);
            font-size: $font-size-small;
            padding: 0.7rem 1rem;
            letter-spacing: 0.0625rem;

            &:hover {
              color: $tonic-color;
            }
          }
        }
      }
    }
  }
}

// ===============================================================================
// BLOCK
// ===============================================================================
//Header
.header--phone {
  font-size: $font-size-medium;

  p {
    line-height: 1.5;
    @include font-family(secondary, regular);
    letter-spacing: 0.0625rem;
  }
}

.icon-header {
  position: relative;
  top: rem-calc(5);
  margin: 0 rem-calc(6);
}

.header--user-connect {
  position: relative;
  line-height: 1;
  // &:before {
  //   display: inline-block;
  //   height: rem-calc(15);
  //   border-right: 1px solid $medium-gray;
  //   content: '';
  //   margin-left: rem-calc(10);
  //   margin-right: rem-calc(10);
  //   position: relative;
  //   top: rem-calc(2);
  //   font-size: $font-size-body;
  // }

  .header--user-name-box {
    display: none;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 50%;
    margin-left: rem-calc(-125);
    width: rem-calc(250);
    background: $primary-alt-color;
    padding: rem-calc(10);
    font-size: $font-size-small;

    ul {
      list-style: none inside none;
      margin: 0;

      li {
        a {
          display: block;
          text-align: left;
          border-bottom: 1px solid $medium-gray;
          padding: rem-calc(8) rem-calc(5);
        }

        &:last-child {
          a {
            border-bottom: 0 none;
          }
        }
      }
    }
  }
}

.header--user-favorites {
  position: relative;
  text-align: left;
  line-height: 1;
  /*&:after {
    content: '|';
    display: inline-block;
    color: $medium-gray;
    position: absolute;
    top: 0;
    right: 0;
  }
  &:before {
    content: '|';
    display: inline-block;
    color: $medium-gray;
    position: absolute;
    top: 0;
    left: 0;
  }*/
}

.header--user-cart {
  text-align: left;
  line-height: 1;
}

.header--user-infos {
  color: $tertiary-color;
  @include font-family(secondary, light);
  display: block;
}

.header-search {
  label, input[type="submit"] {
    display: none;
  }

}

.header-fiches-technique {
  border-left: 1px solid $medium-gray;
  padding: 0 rem-calc(20);

  p {
    margin-bottom: 0;

    a {
      @include font-family(secondary, bold);
      color: $primary-color;
      text-transform: uppercase;

      &:hover {
        color: $tonic-color;
      }

      span {
        @include font-family(secondary, light);
      }

      &:before {
        content: '';
        display: block;
        background: transparent url(#{$base-theme-basepath}assets/images/pictos/ft-fds.svg) top center no-repeat;
        width: rem-calc(70);
        height: rem-calc(60);
        background-size: cover;
      }
    }
  }

  &.header-fiches-technique--internal {
    border-left: 0;

    p {
      a {
        &:before {
          width: rem-calc(50);
          height: rem-calc(50);
          background-size: contain;
          display: inline-block;
        }
      }
    }
  }
}

//Diaporama
.block--diaporama {
  margin-bottom: rem-calc(30);

  .content {
    .diaporama--item {
      position: relative;
      height: rem-calc(250);

      .diaporama--item--body {
        position: absolute;
        top: rem-calc(30);
        right: rem-calc(30);
        z-index: 1;
        font-size: $font-size-xstrong;
        color: $white;
        width: rem-calc(410);
        height: rem-calc(190);
        //display: flex;
        display: none;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: left;

        p {
          flex: none;
          max-width: 100%;
        }
      }

      .diaporama--item--link {
        overflow: hidden;

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: transparent;
          text-indent: rem-calc(-10000);
          z-index: 2;
        }
      }

      .diaporama--item--image {
        background: transparent center no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .diaporama--item--mobile {
        display: none;
      }
    }
  }
}

.js-diaporama--hide-no-first {
  .view-content > *:not(:first-child) {
    display: none;
  }
}


//Menu latéral profil utilisateur
.nav-profil {
  padding-top: rem-calc(32);

  .nav-profil-list {
    list-style: none inside none;
    margin: 0;

    .nav-profil-item {
      .nav-profil-link {
        color: $white;
        background: $primary-color;
        border-bottom: 1px solid $primary-alt-color;
        padding: rem-calc(10) rem-calc(20);
        display: flex;
        justify-content: space-between;

        &:hover, &.active {
          color: $tertiary-color;
          background: $primary-alt-color;
          border-left: 6px solid $tertiary-color;
        }

        &:after {
          font-family: 'icomoon';
          content: '\e908';
        }
      }
    }
  }
}

// ===============================================================================
// VIEWS
// ===============================================================================
//filtres exposés dans les pages
.main--internal {
  .view-filters {
    background: $light-gray;
    padding: 1.1rem 1.2rem 1.5rem 1.2rem;
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(30);
  }
}

//Recherche produits dans les pages intérieures
.header {
  position: relative;
  z-index: 3;

  .views-exposed-widgets {
    position: relative;
    padding-top: 0;

    .views-exposed-widget {
      float: none;
      width: 100%;
      padding: 0;

      .views-widget {
        width: 100%;
      }

      input {
        margin-bottom: 0;
        height: rem-calc(35);
      }
    }

    .form-item {
      position: relative;
      width: 100%;
      /*&:after {
        display: inline-block;
        content: "\e986";
        font-family: 'icomoon';
        color: $tonic-color;
        position: absolute;
        top: 4%;
        right: 3px;
        font-size: 20px;
        background: $off-white;
        padding-right: 10px;
      }*/
    }

    button {
      position: absolute;
      top: 3px;
      right: 1px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      height: calc(100% - 5px);
      margin: 0;
      padding: 0 0 0 9px;
      color: transparent;
      border: 0;
      background: $white;
      overflow: hidden;

      &:before {
        display: inline-block;
        content: "\e986";
        font-family: 'icomoon';
        font-size: 20px;
        color: $tonic-color;
      }
    }
  }

  .header-internal {
    .views-exposed-widgets .views-exposed-widget {
      width: 100%;

      .views-widget {
        width: 100%;
      }

      .form-item {
        &:after {
          background: $off-white;
        }
      }

      input {
        background: $off-white;
      }
    }
  }
}

//Produits home et interieurs
.page-nettoyage, .page-consommables {
  h1.page-title-main, .menu-gammes {
    display: none;
  }
}

.products-list {
  background: $white;
  margin-bottom: rem-calc(20);
  box-shadow: 0px 0px 13px $medium-gray;
  padding: rem-calc(10) rem-calc(10) rem-calc(20) rem-calc(10);
  position: relative;

  &.products-list--page {
    box-shadow: 0 0 0 transparent;
    padding: 0;
  }

  h2 {
    margin: rem-calc(15) 0 rem-calc(25) 0;

    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 15px 15px;
      border-color: transparent transparent transparent $primary-color;
      margin-left: rem-calc(-10);
      margin-right: rem-calc(10);
    }
  }

  &.mark-madeinfrance {
    &:before {
      width: rem-calc(60);
      height: rem-calc(60);
      background: transparent url('#{$base-theme-basepath}assets/images/pictos/madeinfrance.svg') center no-repeat;
      display: inline-block;
      content: '';
      position: absolute;
      top: rem-calc(14);
      right: rem-calc(24);
    }
  }

  .view-content {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;

    .column {
      margin-bottom: rem-calc(15);
    }

    .products-list-item {
      position: relative;
      border-right: 1px solid $medium-gray;
      border-left: 1px solid $medium-gray;
      border-bottom: 1px solid $medium-gray;
      flex-wrap: nowrap;
      transition: all 0.2s;
      position: relative;

      &:hover {
        box-shadow: 0px 0px 13px $medium-gray;

        .products-list--details {
          &:before {
            border-top-width: 6px;
          }
        }
      }

      &:after {
        content: '';
        clear: both;
        display: block;
        visibility: hidden;
      }

      .products-list--image {
        background: transparent no-repeat center;
        background-size: cover;
        width: rem-calc(110);
        height: rem-calc(90);
        float: left;
      }

      .products-list--details {
        padding: rem-calc(5) rem-calc(10);
        float: left;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 0;
          border-top: 3px solid;
          transition: all 0.2s;
        }

        h3 {
          color: $black-light;
          font-size: $font-size-body;
          @include font-family(primary, bold);
          margin: rem-calc(10) 0 rem-calc(5) 0;
        }

        p {
          margin: 0;
          @include font-family(secondary, regular);
          font-size: $font-size-small;
          color: $dark-gray;
          line-height: 1;
        }
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:hover {
        h3,
        a,
        &:before,
        p {
          color: $tonic-color;
        }
      }

      &:before {
        content: '+';
        position: absolute;
        bottom: rem-calc(5);
        right: rem-calc(5);
        color: $primary-alt-color;
      }
    }
  }
}

// Fiches techniques / FDS
.view-fiches-doc {
  .views-widget-filter-delta,
  .views-widget-filter-delta_1 {
    & > label {
      display: none;
    }
  }
}

.fiches-doc-row {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 1rem;
  margin-bottom: rem-calc(20);

  .fiches-doc {
    margin: 0 rem-calc(10) 0 rem-calc(10);

    .fiches-doc-title {
      margin-bottom: rem-calc(5);
      @include font-family(secondary, light);

      a {
        color: $black;

        &:hover {
          color: $tonic-color;
        }
      }
    }

    .fiches-doc-type {
      padding: rem-calc(5) 0;
    }

    .fiches-doc-size {
      padding: rem-calc(5) 0;

    }

    .fiches-doc-download {
      padding: rem-calc(5) 0;
    }

    .fiches-doc-links {
      &:hover {
        background: $light-gray;
        color: $primary-alt-color;
      }
    }
  }
}

.view-infos-consommateurs {
  .view-content {
    padding: 0 1.2rem;
  }
}
//Page carnet d'adresse
#user-addressbook-wrapper {
  .form-wrapper {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;

    h2 {
      @include font-family(primary, bold);
      font-size: $font-size-body;
      // margin-bottom: 0.625rem;
      margin-bottom: 0.875rem;
      padding: 0 0.125rem;

      a {
        color: $primary-color;
        cursor: default;
      }
    }

    .views-row-odd {
      border-right: 1px dotted $medium-gray;
    }

    & > p:first-child {
      font-size: 0;
    }

    .column {
      border-bottom: 1px dotted $medium-gray;
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
    }

    .views-field-rendered-entity {
      height: 100%;
      max-height: calc(100% - #{rem-calc(35)});
    }

    .field-name-field-budget-address {
      display: inline-flex;
      padding: rem-calc(4) rem-calc(12);
      @include font-family(primary, bold);
      font-size: 0.875rem;
      color: $primary-color;
      background: rgba($secondary-color, 0.1);

      & + .field-name-user-addressbook-address {
        margin-top: 0;
      }
    }

    .field-name-user-addressbook-address {
      background: $off-white;
      padding: rem-calc(8) rem-calc(12);
      margin: rem-calc(10) 0;
    }

    .views-field-edit-user-address {
      width: 49%;
      display: inline-block;
      margin-top: rem-calc(10);

      a {
        &:before {
          content: '\e905';
          font-family: 'icomoon';
          margin-right: rem-calc(5);
        }
      }
    }

    .views-field-delete-user-address {
      width: 49%;
      display: inline-block;
      margin-top: rem-calc(10);

      a {
        &:before {
          content: '\e90e';
          font-family: 'icomoon';
          margin-right: rem-calc(5);
        }
      }
    }

    .views-field-user-address-set-default {
      display: none;
    }
  }

}

.page-user {
  h3.text-color-secondary {
    text-transform: none;

    & + span.form-required {
      display: none;
    }
  }
}


.page-user, .node-type-commandes-type {
  .tabs-primary {
    display: none;
  }
}

.page-user-addresses .main--internal .action-links {
  list-style: none inside none;
  text-align: right;

  a {
    position: absolute;
    top: auto;
    right: 1rem;
    padding: $button-padding;
    font-size: $font-size-body;
    transition: all 0.3s;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.625rem;
    padding: 0.55rem 1rem;
    border: 1px solid transparent;
    border-radius: 0;
    transition: all 0.25s ease-out;
    font-size: 0.9rem;
    font-size: $font-size-body;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: $primary-color;
    color: $white;

    &:hover {
      color: $tertiary-color;
      background: $primary-alt-color;
      border-left: 6px solid $tertiary-color;
    }
  }
}

// Espace perso - Historique commandes liste
// ------
.view-commerce-user-orders {
  .views-exposed-widgets { //Filtres
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    .views-exposed-widget {
      float: none;
      display: inline-block;
      padding: 0;

      label {
        margin-bottom: 0;
      }

      #edit-date-filter-min-wrapper, #edit-date-filter-max-wrapper {
        display: inline-block;

        input {
          margin-bottom: 0;
          width: 95%;
        }

        .description {
          color: $dark-gray;
          font-style: italic;
        }
      }

      .views-widget {
        display: flex;
        flex-wrap: nowrap;
      }

      .form-submit {
        margin: rem-calc(10) rem-calc(10) 0 0;
        padding: rem-calc(12) rem-calc(40);
      }
    }

    .views-widget-filter-status_1 {
      width: 50%;

      .form-item-status-1 {
        width: 95%;
        margin-right: 5px;
      }

      .select2 {
        width: 100% !important;
      }
    }
  }

  tr {
    img {
      display: inline-block;
      margin: 0 0 0 5px;
      position: relative;
      top: rem-calc(-5);
    }
  }

  .views-field-order-number {
    width: 4.125rem;
  }

  .views-field-created {
    width: rem-calc(130);
  }

  .views-field-commerce-customer-shipping {
    .field-name-field-autres,
    .field-name-field-contacts,
    .field-name-field-horaires {
      display: none;
    }
  }

  .views-field-commerce-order-total {
    text-align: right;
  }

  .views-field-commerce-reorder-button {
    width: rem-calc(140);
  }

  .views-field-uid {
    width: 12rem;

    a, button {
      display: block;
      margin: 0 0 rem-calc(10);
      width: 100%;
    }
  }

  .form-actions {
    display: none;
  }

}

// Espace perso - Validateur de commande - historique par adresse.
// ------
#addresses-history {
  padding-top: 3rem;
}
.addresses-history-address{
  background: #efefef;
  padding: 1rem 2rem;
}
.addresses-history-address-name{
  font-weight: bold;
}
.commerce-order-addresses-history {
  padding-top: 1rem;

  .waiting-order-number-info {

    position: relative;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: 1px solid $quatrary-color;
    cursor: pointer;

    &:after {
      content: '+';
      position: absolute;
      display: inline-block;
      right: 1.5rem;
      top: .5rem;
      font-size: 2rem;
      color: $light-gray;
    }

    &.is-open:after {
      content: '-';
    }

    &:hover {
      background-color: $quatrary-color;

      &:after {
        color: $primary-color;
      }
    }


  }
  .content {
    display: none;
  }

}
// Espace perso - Historique commandes details
// ------
.reveal-modal--order,
.commerce-order-commerce-order {
  h3 {
    margin-bottom: rem-calc(20);
  }

  .views-field-commerce-unit-price,
  .views-field-quantity {
    text-align: right;
  }

  .view-content.row {
    margin: 0;
  }

  .order--row {
    .fieldset-wrapper {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
    }
  }

  #pit-commerce-commerce-reorder-details-form {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .order--total {
    @include font-family(secondary, light);
    font-size: $font-size-strong;
    color: $primary-color;
  }

  .commerce-price-formatted-components {
    width: atuo;
    margin-left: auto;
    margin-bottom: 1.625rem;

    tr.component-type-commerce-price-formatted-amount {
      background-color: $quatrary-color;
      font-weight: bold;
    }
  }

  .field-name-field-commerce-ref-client {
    margin-bottom: 1rem;

    div {
      display: inline;
    }
  }
}

// Espace perso - Commande en attente de validation
// ------
.waiting-order-above {
  padding: 0 0.25rem;
}

.waiting-order-number-info {
  font-weight: 700;
}

.waiting-order-number {
  font-size: 1.375rem;
  color: $primary-color;
  line-height: 1;
}

.waiting-order-bond {
  margin: 0 .1875rem;
}

.waiting-order-credit {
  font-size: 1.125rem;
  color: $primary-color;
}

.waiting-order-fieldset {
  border: 1px solid $primary-color;
  margin-top: 0.5rem;

  .column {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
}

.waiting-order-fieldset-header {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: $off-white;
}

.waiting-order-fieldset-order,
.waiting-order-fieldset-address {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.waiting-order-fieldset-address {
  .field-name-field-horaires,
  .field-name-field-contacts,
  .field-name-field-autres {
    display: none;
  }
}

.waiting-order-fieldset-order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
}

.waiting-order-fieldset-total-amount {
  // margin-bottom: 0.5rem;
  margin-top: 0.0625rem;
  margin-bottom: 0.625rem;
}

// Espace perso - Adresses en attente de validations
// ------
.address-to-validate {
  margin-top: 0.875rem;
  margin-bottom: 1.625rem;
}

.waiting-address-above {
  padding: 0 0.25rem 0 0.125rem;
}

.waiting-address-name {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-right: 0.25rem !important;
  line-height: 1;
  @include font-family(primary, bold);
  font-size: 1.125rem;
  color: $primary-color;
}

.waiting-address-username {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  line-height: 1;
  @include font-family(primary, medium);
  font-size: 0.875rem;
  color: $primary-color;
}

.waiting-address-fieldset {
  border: 1px solid $primary-color;
  margin-top: 0.5rem;
}

.waiting-address-address {
  padding: 0.5625em 0.9375rem 0.75rem;
}

// Waiting validators
// ------
.waiting-validators-header {
  padding: 0.25rem 1.25rem;
  line-height: 1.25;
  font-size: 0.875rem;
  color: $white;
  background-color: $dark-gray;
}

.waiting-validators {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  background-color: $off-white;
}

.waiting-validator {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  // padding-top: 0.4375rem;
  // padding-bottom: 0.5625rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.4375rem;

  &:not(.single-validator) {
    &:nth-child(3n+1),
    &:nth-child(3n+2) {
      &:after {
        content: '';
        position: absolute;
        // top: 0.625rem;
        top: 0.5rem;
        right: 0;
        // height: calc(100% - 1.375rem);
        height: calc(100% - 1.125rem);
        width: 1px;
        background-color: $dark-gray;
      }
    }
  }
}

.waiting-validator-name {
  @include font-family(primary, medium);
}

// Waiting states
// ------
.waiting-state-text {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.waiting-state-text-pending {
  color: $primary-color;
}

.waiting-state-text-validated {
  color: $success-color;
}

.waiting-state-text-denied {
  color: $alert-color;
}

.waiting-order-actions {
  margin-top: 0.25rem;
}

// Order Toal
// ------
.commerce-price-formatted-components {
  tbody {
    border: 1px solid $primary-color;

    > :last-child {
      border-bottom: 0;
    }
  }

  .component-type-commerce-price-formatted-amount {
    font-weight: bold;
  }
}

// Panier
// ------
.commerce-line-item-views-form, .views-form {
  width: 100%;
}

.view-commerce-cart-form.view-id-commerce_cart_form {
  // margin-top: rem-calc(40);
  margin-top: 2rem;
}

.page-cart {
  h1.page-title-main {
    color: $secondary-color;
    font-size: $font-size-xstrong;
    text-transform: uppercase;
    @include font-family(secondary, light);

  }

  .form-actions-top {
    margin-top: 0;
    margin-bottom: 1.625rem;
  }

  .block-commerce-checkout-progress {
    display: none;
  }

  .view-commerce-cart-block .price,
  .view-commerce-cart-form .price,
  .view-commerce-cart-summary .price,
  .views-field-edit-quantity,
  .views-field-commerce-unit-price,
  .views-field-commerce-total {
    text-align: right;
    width: rem-calc(200);
  }

  .line-item-summary {
    padding-right: rem-calc(10);
  }

  .form-actions {
    button {
      margin: 0 rem-calc(10) 0 0;
    }
  }

  .views-field-edit-delete {
    width: 4rem;
    font-size: 1.25rem;

    .delete-line-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 0.25rem;
      color: $medium-gray;
    }
  }

  .commerce-order-handler-area-order-total,
  .commerce-order-handler-area-order-total ~ p {
    padding: 0 rem-calc(15);
  }

  .views-field-checkbox-delete {
    text-align: center;
    width: 4rem;

    &:not(.is-shown) {
      display: none;
    }
  }

  .delete-line-checkbox-item {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: rem-calc(34);
    width: rem-calc(34);
    margin-top: rem-calc(3);
    border: 1px solid $medium-gray;
    font-size: 1.125rem !important;
    color: $primary-color;
    background: $white;

    .delete-line-checkbox-item--input {
      &:not(:checked) ~ .delete-line-checkbox-item--icon {
        visibility: hidden;
      }
    }
  }
}

.commerce-order-handler-area-order-total {
  .commerce-price-formatted-components {
    width: 100%;
    max-width: calc(#{$global-width} * (50 / 100));
  }
}

// Tunnel d'achat
.page-checkout {
  .checkout-over-budget-wrapper {
    text-align: center;
    margin-bottom: 1.875rem;
  }

  .checkout-over-budget {
    display: inline-block;
    color: $alert-color;
    background: rgba($alert-color, 0.1);
    padding: 0.4375rem 1.25rem 0.5rem;
    border-radius: 0.5rem;
    //display: inline-block;
    font-size: 0.875rem;
  }

  #commerce-shipping-service-ajax-wrapper {
    display: none;
  }

  .cart_contents .fieldset-legend,
  .pit_co_commentary .fieldset-legend,
  .pit_co_wished_shipping_date .fieldset-legend,
  .form-item-pit-co-ref-client-pit-co-ref-client-pane-ref-field label {
    color: $secondary-color;
    font-size: $font-size-xstrong !important;
    text-transform: uppercase;
    @include font-family(secondary, light);
  }

  .pit_co_commentary .fieldset-wrapper {
    .form-item:first-child {
      margin-top: 0;
    }

    .form-type-checkbox > .option {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-transform: none;
      color: $black-light;
      font-size: 1rem;

      &:before {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $medium-gray;
        border-radius: 0;
        line-height: 1.4375rem;
        color: $primary-color;
        font-size: rem-calc(15);
        background: $white;
      }

      em {
        text-transform: uppercase;
        padding-right: rem-calc(5);
        padding-left: rem-calc(3);
      }
    }
  }

  .pit_co_wished_shipping_date .fieldset-wrapper {
    .form-item:first-child {
      margin-top: 0;
    }

    .form-item.form-type-date-text {
      margin-bottom: 0.875rem;
    }

    .date-date {
      + .description {
        color: $dark-gray;
        font-style: italic;
      }

      > .form-text {
        margin-bottom: 0;
      }
    }
  }

  #commerce-checkout-form-addresses {
    .views-form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: 1.25rem;
    }

    .views-row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: rem-calc(14);
      // padding-bottom: rem-calc(14);
      // padding-top: rem-calc(16);
      padding-bottom: rem-calc(16);
      border-bottom: 1px dotted $medium-gray;

      &:not(:nth-of-type(3n+3)) {
        border-right: 1px dotted $medium-gray;
      }
    }

    h2 {
      @include font-family(primary, bold);
      font-size: $font-size-body;
      margin-bottom: 0.625rem;
      margin-bottom: 0.875rem;
      padding: 0 0.125rem;
      width: 100%;
      color: $primary-color;

      .address-free {
        position: relative;
        top: rem-calc(-2);
        display: inline-block;
        padding: rem-calc(3) rem-calc(6);
        border-radius: rem-calc(3);
        line-height: 1;
        font-size: rem-calc(10);
        color: $white;
        text-transform: uppercase;
        background: $tonic-color;
      }

      .address-subinfo {
        display: block;
        margin: 0.375rem 0 0;
        line-height: 1;
        @include font-family(secondary, light);
        // font-style: italic;
        font-size: $font-size-small;
        color: $dark-gray;
      }
    }

    .views-field-field-budget-address {
      display: inline-block;
      padding: rem-calc(4) rem-calc(12);
      width: auto;
      @include font-family(primary, bold);
      font-size: 0.875rem;
      color: $primary-color;
      background: rgba($secondary-color, 0.1);

      & + .views-field-user-addressbook-address {
        margin-top: 0;
      }

      &.over-budget {
        color: $alert-color;
        background: rgba($alert-color, 0.1);
      }
    }

    .views-field-user-addressbook-address {
      flex: 1;
      width: 100%;

      & > .field-content {
        background: $off-white;
        padding: rem-calc(8) rem-calc(12);
      }

      // margin: rem-calc(10) 0;
      margin: 0;
    }

    .views-field-user-address-commerce-checkout-select-user-address {
      width: 100%;
      // margin-top: rem-calc(14);
      margin-top: rem-calc(12);
    }

    #edit-continue {
      display: none;
    }
  }
}

#commerce-checkout-form-review .view-commerce-cart-summary .view-footer p {
  display: none;
}

.block-commerce-checkout-progress {
  ol.commerce-checkout-progress {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
    margin: rem-calc(40) 0;

    li {
      flex: 1;
      position: relative;
      // margin: 0 rem-calc(10) 0 0;
      margin: 0 rem-calc(12) 0 0;
      // padding: rem-calc(8) rem-calc(2) rem-calc(10) rem-calc(12);
      padding: rem-calc(8) rem-calc(2) rem-calc(10) rem-calc(14);
      line-height: 1.25;
      list-style: none inside none;
      color: $white;
      background: $tertiary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        // border-width: 30px 0 30px 10px;
        border-width: rem-calc(40) 0 rem-calc(40) rem-calc(12);
        transform: translateY(-50%);
      }

      &:after {
        // right: rem-calc(-10);
        right: rem-calc(-12);
        border-color: transparent transparent transparent $tertiary-color;
      }

      &:before {
        left: 0;
        border-color: transparent transparent transparent $white;
      }

      &.active {
        background: darken($tertiary-color, 20%);

        &:after {
          border-color: transparent transparent transparent darken($tertiary-color, 20%);
        }
      }

      // &.addresses {
      //   flex: 3;
      // }
    }
  }
}

.checkout-buttons .fieldset-wrapper {
  .button-operator {
    display: none;
  }

  #edit-cancel, #edit-back {
    border: 1px solid $primary-color;
    color: $primary-color;
    padding: .75rem 2rem;

    &:hover {
      color: $white;
      background-color: $primary-color;
      text-decoration: none;
    }
  }
}

.checkout-buttons .fieldset-wrapper #edit-continue,
.checkout-completion-button {
  float: right;
  padding: .75rem 2rem;
}

.pit_co_ref_client {
  & > legend {
    display: none;
  }

  padding-bottom: 1rem;

  #edit-pit-co-ref-client-pit-co-ref-client-pane-ref-field {
    width: auto;
  }
}

.pit_co_wished_shipping_date {
  padding-bottom: 1rem;
}

#edit-user-addressbook-customer-profile {
  margin-bottom: 1.25rem;
}

.checkout-addressbook-link {
  // margin-bottom: 1.125rem;
  margin-bottom: 1.25rem;

  .fieldset-legend {
    color: $secondary-color;
    font-size: $font-size-xstrong;
    text-transform: uppercase;
    @include font-family(secondary, light);
  }
}

.checkout-addressbook-new-address .fieldset-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 1.25rem;
    flex-shrink: 0;
  }
}

#user-addressbook-ajax-wrapper {
  .fieldset-legend {
    color: $secondary-color;
    font-size: $font-size-xstrong;
    text-transform: uppercase;
    @include font-family(secondary, light);
  }

  .form-item-user-addressbook-add-to-user-addressbook,
  .form-item-user-addressbook-user-address-set-default {
    display: none !important;
  }

  #edit-user-addressbook-save {
    float: right;
  }

  #edit-user-addressbook-cancel {
    border: 1px solid $primary-color;
    color: $primary-color;
    background: transparent;
    margin-bottom: rem-calc(24);

    &:hover {
      color: $white;
      background-color: $primary-color;
    }
  }

  .messages--status {
    display: none;
  }
}

.pane-data-full {
  #customer-profile-shipping,
  #customer-profile-billing {
    display: inline-block;
    width: 48%;
    vertical-align: top;
  }
}

// .add-to-cart-overlay,
.add-cart-message-wrapper {
  display: none;
}

// ===============================================================================
// NODES
// ===============================================================================
// CATALOGUE
// ------
.node-pages-catalogue {
  &.node-teaser {
    min-height: 100%;
    padding: 10px 10px;

    .node-content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
      border: 1px solid $medium-gray;
      overflow: hidden;
      transition: all 0.2s;

      &:hover {
        box-shadow: 0px 0px 13px $medium-gray;
      }

      .commerce-product-item-info {
        flex: 1 1 auto;
        cursor: pointer;
        // position: relative;
        // .commerce-product-item-link {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        // }
      }

      h2 {
        font-size: rem-calc(28);
        padding-top: rem-calc(10);
        position: relative;

        a {
          display: block;
          padding-right: rem-calc(30);

          &:after {
            position: absolute;
            right: rem-calc(5);
            top: rem-calc(5);
            content: '+';
            font-size: $font-size-body;
            color: $black;
          }

          // &:hover {
          //   span {
          //     color: $black;
          //   }
          // }
        }
      }

      p {
        padding-right: rem-calc(20);

        &.emballage {
          letter-spacing: -1px;
        }
      }

      .node-content--desc {
        padding-top: 0.125rem;
        font-size: rem-calc(14);
      }

      .node-content--stock {
        padding-top: 0.25rem;
        font-size: rem-calc(14);
      }

      .node-content--body {
        p {
          &:first-letter {
            text-transform: uppercase;
          }
        }
      }

      .node-content--labels {

      }

      .commerce-product-item-img-container {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        & > a {
          display: block;
        }
      }

      .commerce-product-item-img {
        border: 10px solid $white;
        // max-height: 100%;
        max-height: 15.5rem;
      }

      .node-content--body {
        p {
          margin: 0;
          font-size: $font-size-small;
        }
      }

      .node-content--price {
        border-top: 1px dashed $medium-gray;
        margin: rem-calc(10) 0 0 0;
        // padding: rem-calc(10) 0 rem-calc(5) 0;
        padding: rem-calc(8) 0 rem-calc(12) 0;

        [class^="icon-"] {
          position: relative;
          top: rem-calc(3);
        }
      }

      .commerce-product-item-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        margin: 0;
      }

      .commerce-cdetype-additem {
        flex: 1;
        display: block;
        width: 100%;
        text-align: center;
        padding: rem-calc(10) 0;
        cursor: pointer;
        color: $primary-color;

        &:hover {
          background: $quatrary-color;
        }

        &.commerce-cdetype-additem-checked {
          background-color: $off-white;
          color: $medium-gray;
          cursor: default;

          &:hover {
            background-color: $off-white;
            color: $medium-gray;
          }
        }
      }

      .commerce-product-addcart {
        flex: 1;
        align-self: flex-end;
        display: block;
        // position: absolute;
        // bottom: 0;
        // right: 0;
        position: relative;
        width: 100%;
        height: 100%;
        background: $primary-color;

        &.commerce-product-addcart-disabled {
          width: 100%;

          .commerce-addcart-container {
            width: 100%;
          }
        }

        &:hover {
          background: $primary-alt-color;
          color: $tertiary-color;
        }

        .commerce-addcart-button {
          display: block;
          padding: rem-calc(10) 0;
          color: $white;
          text-align: center;
          cursor: pointer;
        }

        .commerce-addcart-container {
          position: absolute;
          bottom: 0;
          left: 0;
          display: none;
          width: 200%;
          border-top: 2px solid $primary-color;
          background: $white;
          z-index: 1;

          @include font-family(secondary, light);

          .commerce-addcart-button-close {
            position: absolute;
            top: rem-calc(5);
            right: rem-calc(20);
            font-size: $font-size-medium;
            cursor: pointer;
            color: $medium-gray;
            padding: rem-calc(5) rem-calc(10);
          }

          .form-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: rem-calc(10) 0;

            label {
              // width: 50%;
              // text-align: right;
              padding-right: 0.5rem;
              display: inline-block;
            }

            input {
              width: 25%;
              display: inline-block;
            }
          }

          .button {
            width: 100%;
            text-align: center;
            margin: 0;
          }
        }

        .ajax-progress {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: rem-calc(45);
          background: rgba($white, 0.9);
          z-index: 2;
        }

        .ajax-progress-throbber {
          display: none !important;
        }

        .commerce-addcart-anonymous {
          color: $primary-color;
          text-align: center;
          padding: rem-calc(20) rem-calc(40) rem-calc(20) rem-calc(10);
          margin: 0;
          cursor: pointer;
          display: none; /*Remove from add cart*/
          &:hover {
            color: $secondary-color;
          }
        }
      }

      .commerce-product-success {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($white, 0.9);
        display: none;
        padding: rem-calc(10);
        overflow: hidden;
        z-index: 1;

        .commerce-product-success-close {
          width: 100%;
          margin: rem-calc(10) 0 0;
          cursor: pointer;

          &:hover {
            color: $tertiary-color;
            background-color: $primary-alt-color;
          }
        }

        .commerce-product-success-content {
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          flex-flow: row wrap-reverse;
          justify-content: center;
          align-items: center;

          .row {
            width: 100%;
          }

          .shopping-cart-checkout {
            a {
              width: 100%;
              margin-top: rem-calc(10);
              color: $white;
              display: block;
              background-color: $primary-color;
              padding: rem-calc(10);

              &:hover {
                color: $tertiary-color;
                background-color: $primary-alt-color;
              }
            }
          }

          .icone-cart-ok {
            width: rem-calc(100);
            height: rem-calc(100);
            background: transparent url('#{$base-theme-basepath}assets/images/pictos/cart-ok.svg') center no-repeat;
            background-size: cover;
            display: block;
            margin: rem-calc(30) auto 0 auto;
            text-align: center;
          }
        }
      }
    }

    .commerce-addfav-return {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, 0.9);
      text-align: center;
      color: $primary-color;
      font-size: $font-size-strong;

      &.error {
        color: red;
      }

      .commerce-addfav-return-details {
        height: 100%;
      }

      [class^="icon-"], [class*=" icon-"] {
        display: block;
        font-size: $font-size-xxxstrong;
      }

      .icon-star-empty {
        font-size: $font-size-xxxxstrong;
        color: $medium-gray;
      }

      .icon-checkmark, .icon-info, .icon-blocked {
        text-shadow: $white 7px 7px, $white -7px 7px, $white -4px -7px, $white 7px -7px;
        margin: rem-calc(-55) rem-calc(30) 0 rem-calc(-55);
      }

      .icon-info {
        text-shadow: transparent 0 0;
        padding: 5px;
        border-radius: 50%;
        background: $white;
      }
    }
  }

  &.view-mode-full {
    position: relative;

    &.loading {
      &:after {
        content: '';
        background: rgba($black, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &:before {
        content: '';
        display: inline-block;
        margin: 100px auto;
        font-size: 25px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin-top: rem-calc(-20);
        margin-left: rem-calc(-20);
        text-indent: -9999em;
        -webkit-animation: load5 1.1s infinite ease;
        animation: load5 1.1s infinite ease;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
    }

    .product--title {
      margin: 0.875rem 0 1.25rem;
    }

    .product--images {
      margin-top: 2rem;

      .product--images-details {
        position: relative;
      }

      .product--images-details-img {
        max-width: 100%;
        max-height: rem-calc(250);
      }

      .product--images-details-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    .product--reference {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      border: 1px solid $dark-gray;
      color: $dark-gray;
      font-size: $font-size-body;
      margin-bottom: 0;
      float: left;
      clear: left;

      &:not(:first-child) {
        margin-top: 0.375rem;
      }

      .ref-label {
        background: $dark-gray;
        color: $white;
        padding: rem-calc(5) rem-calc(8) rem-calc(5) rem-calc(12);
      }

      .ref-sku {
        padding: rem-calc(5) rem-calc(10);
      }
    }

    .product--labels {
      padding-right: rem-calc(30);
      line-height: 1;

      &:before {
        border-left: 1px solid $medium-gray;
        content: "";
        display: inline-block;
        height: rem-calc(30);
        margin-right: rem-calc(15);
        position: relative;
        top: rem-calc(-8);
      }

      img {
        height: rem-calc(50) !important;
        width: auto;
      }

      .node-content--labels-details {
        width: rem-calc(50);
        height: rem-calc(50);
      }
    }

    .product--body {
      padding: rem-calc(20) rem-calc(30) 0 0;
      clear: both;
    }

    .product--price {
      border-bottom: 1px dashed $medium-gray;
      margin-bottom: rem-calc(20);

      .product--price-label {
        font-size: $font-size-small;
      }

      .product--price-details {
        font-size: $font-size-xxstrong;
      }

      .product--price-ttc {
        font-size: $font-size-strong;
      }

      .icon-price {
        font-size: $font-size-xxxstrong;
        margin-left: rem-calc(-50);
        top: rem-calc(5);
        position: relative;
      }

      .product--tgap {
        color: $dark-gray;
      }

      .product--tgap--notice {
        display: block;
        // padding-top: 0.1875rem;
        padding-top: 0.25rem;
        line-height: 1.4;
        font-size: 0.8125rem;
        color: $dark-gray;
      }
    }

    .form-item-quantity {
      padding-left: rem-calc(5);
    }

    .form-submit {
      width: 100%;
      line-height: rem-calc(30);
      padding: 0 0 rem-calc(7) 0;
      @include font-family(primary, regular);

      &:before {
        content: "\e904";
        font-family: 'icomoon';
        font-size: $font-size-xstrong;
        top: rem-calc(6);
        position: relative;
        margin-right: rem-calc(10);
      }
    }

    .js-price, .js-price-e {
      display: none;
    }

    .form-item-quantity {
      label {
        display: inline-block;
        font-size: $font-size-small;
      }

      input {
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }

    .commerce-product-success-content {
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row wrap-reverse;
      justify-content: center;
      align-items: center;

      .row {
        width: 100%;
      }

      .shopping-cart-checkout {
        a {
          width: 100%;
          margin-top: rem-calc(10);
          color: $white;
          display: block;
          background-color: $primary-color;
          padding: rem-calc(10);
          border: 1px solid $primary-color;

          &:hover {
            color: $primary-color;
            background-color: $white;
          }
        }
      }

      .icone-cart-ok {
        width: rem-calc(100);
        height: rem-calc(100);
        background: transparent url('#{$base-theme-basepath}assets/images/pictos/cart-ok.svg') center no-repeat;
        background-size: cover;
        display: block;
        margin: 0 auto 0 auto;
        text-align: center;
      }

      .commerce-product-success-close {
        width: 100%;
        margin-bottom: 0;
      }

      .shopping-cart-checkout {
        margin-bottom: rem-calc(10);
      }
    }

    .commerce-addcart-anonymous {
      background: $medium-gray;
      cursor: pointer;
      text-align: center;
      padding: rem-calc(10);
      display: none; /*Remove from add cart*/
      color: $primary-color;

      &:hover {
        color: $secondary-color;
      }
    }

    .commerce-cdetype-additem {
      background: $off-white;
      color: $primary-color;
      cursor: pointer;
      margin-top: 0.875rem;
      padding: rem-calc(10);
      text-align: center;

      &:hover {
        background: $quatrary-color;
      }

      &.commerce-cdetype-additem-checked {
        background-color: $off-white;
        color: $medium-gray;
        cursor: default;

        &:hover {
          background-color: $off-white;
          color: $medium-gray;
        }
      }
    }

    .commerce-addfav-return {
      display: block;
      padding-top: 0.3125rem;

      .commerce-addfav-return-details {
        padding: rem-calc(10);
        font-size: $font-size-small;
        line-height: 1;
        flex-wrap: nowrap;

        [class^="icon-"] {
          margin-right: rem-calc(5);
        }

        .icon-star-empty {
          display: none;
        }
      }

      &.checked {
        color: green;
      }

      &.error {
        color: red;
      }

      &.alert {
        color: orange;
      }
    }

    .product--form-addcart {
      display: none;
    }
  }

  .node-content--labels-details {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: rem-calc(40);
    height: rem-calc(40);

    &.node-content--labels-details-AER { //Aerosol
      background-image: url(#{$base-theme-basepath}assets/images/labels/aerosol.svg);
    }

    &.node-content--labels-details-ASPIR { //Aspiration
      background-image: url(#{$base-theme-basepath}assets/images/labels/aspirateur.svg);
    }

    &.node-content--labels-details-AUTOLAV { //Autolaveuse
      background-image: url(#{$base-theme-basepath}assets/images/labels/autolaveuse.svg);
    }

    &.node-content--labels-details-AUTOBRI { //Autolaveuse brillance
      background-image: url(#{$base-theme-basepath}assets/images/labels/autolaveuse-brillance.svg);
    }

    &.node-content--labels-details-BRI { //Brillance
      background-image: url(#{$base-theme-basepath}assets/images/labels/brillance.svg);
    }

    &.node-content--labels-details-BUR { //Bureau
      background-image: url(#{$base-theme-basepath}assets/images/labels/bureaux.svg);
    }

    &.node-content--labels-details-COAL { //Contact alimentaire
      background-image: url(#{$base-theme-basepath}assets/images/labels/contact-alimentaire.svg);
    }

    &.node-content--labels-details-DESINF { //Désinfection
      background-image: url(#{$base-theme-basepath}assets/images/labels/desinfection.svg);
    }

    &.node-content--labels-details-DISTAV { //Distributeur savon
      background-image: url(#{$base-theme-basepath}assets/images/labels/distributeur-savon.svg);
    }

    &.node-content--labels-details-DS { //Double-seau
      background-image: url(#{$base-theme-basepath}assets/images/labels/double-seau.svg);
    }

    &.node-content--labels-details-DSBRI { //Double-seau brillance
      background-image: url(#{$base-theme-basepath}assets/images/labels/double-sceau-brillance.svg);
    }

    &.node-content--labels-details-ECRT { //Ecocert
      background-image: url(#{$base-theme-basepath}assets/images/labels/ecocert.svg);
    }

    &.node-content--labels-details-ECLB { //Ecolabel
      background-image: url(#{$base-theme-basepath}assets/images/labels/ecolabel.svg);
    }

    &.node-content--labels-details-ECR { //Ecoreflex
      background-image: url(#{$base-theme-basepath}assets/images/labels/eco-reflex.svg);
    }

    &.node-content--labels-details-ECORN { //Ecoreflex Nature
      background-image: url(#{$base-theme-basepath}assets/images/labels/ecoreflex-nature.svg);
    }

    &.node-content--labels-details-ECORS { //Ecoreflex Sécurité
      background-image: url(#{$base-theme-basepath}assets/images/labels/ecoreflex-securite-2.svg);
    }

    &.node-content--labels-details-GRAFF { //Graffiti
      background-image: url(#{$base-theme-basepath}assets/images/labels/graffitis.svg);
    }

    &.node-content--labels-details-HACCP { //HACCP
      background-image: url(#{$base-theme-basepath}assets/images/labels/haccp.svg);
    }

    &.node-content--labels-details-HP { //Haute pression
      background-image: url(#{$base-theme-basepath}assets/images/labels/haute-pression.svg);
    }

    &.node-content--labels-details-INJEXT { //Injection extraction
      background-image: url(#{$base-theme-basepath}assets/images/labels/injection-extraction-moquette.svg);
    }

    &.node-content--labels-details-LAVENT { //Lavabo entretien
      background-image: url(#{$base-theme-basepath}assets/images/labels/lavabo-entretient.svg);
    }

    &.node-content--labels-details-LAVREE { //Lavabo remis en état
      background-image: url(#{$base-theme-basepath}assets/images/labels/lavabo-remise-etat.svg);
    }

    &.node-content--labels-details-LESS { //Lessivage
      background-image: url(#{$base-theme-basepath}assets/images/labels/lessivage.svg);
    }

    &.node-content--labels-details-MONOBRO { //Monobrosse
      background-image: url(#{$base-theme-basepath}assets/images/labels/monobrosse.svg);
    }

    &.node-content--labels-details-MONOBRI { //Monobrosse brillance
      background-image: url(#{$base-theme-basepath}assets/images/labels/monobrosse-brillance.svg);
    }

    &.node-content--labels-details-MONOBROM { //Monobrosse moquette
      background-image: url(#{$base-theme-basepath}assets/images/labels/monobrosse-moquete.svg);
    }

    &.node-content--labels-details-NETVIT { //Nettoyage vitres
      background-image: url(#{$base-theme-basepath}assets/images/labels/nettoyage-vitres.svg);
    }

    &.node-content--labels-details-PEINT { //Peinture
      background-image: url(#{$base-theme-basepath}assets/images/labels/peinture.svg);
    }

    &.node-content--labels-details-VMACH { //Vaisselle machine
      background-image: url(#{$base-theme-basepath}assets/images/labels/vaisselle-machine.svg);
    }

    &.node-content--labels-details-VMANU { //Vaisselle manuelle
      background-image: url(#{$base-theme-basepath}assets/images/labels/vaisselle-manuelle.svg);
    }

    &.node-content--labels-details-VAP { //Vaporisateur
      background-image: url(#{$base-theme-basepath}assets/images/labels/vaporisation.svg);
    }

    &.node-content--labels-details-WC { //WC
      background-image: url(#{$base-theme-basepath}assets/images/labels/wc.svg);
    }

    &.node-content--labels-details-COS { //Cosmétique
      background-image: url(#{$base-theme-basepath}assets/images/labels/cosmetique.svg);
    }

    &.node-content--labels-details-APPLI { //application cire
      background-image: url(#{$base-theme-basepath}assets/images/labels/aspirateur-cire.svg);
    }

    &.node-content--labels-details-FEF { // Frabriqué en France
      background-image: url(#{$base-theme-basepath}assets/images/labels/produits-francais.svg);
    }

    &.node-content--labels-details-TORK { // marque TORK
      background-image: url(#{$base-theme-basepath}assets/images/labels/tork.png);
    }

    &.node-content--labels-details-DEB { // marque DEB
      background-image: url(#{$base-theme-basepath}assets/images/labels/deb.png);
    }

    &.node-content--labels-details-NUMATIC { // marque NUMATIC
      background-image: url(#{$base-theme-basepath}assets/images/labels/numatic.png);
    }

    &.node-content--labels-details-3M { // marque 3M
      background-image: url(#{$base-theme-basepath}assets/images/labels/3m.svg);
    }

    &.node-content--labels-details-MAKITA { // marque MAKITA
      background-image: url(#{$base-theme-basepath}assets/images/labels/makita.png);
    }

    &.node-content--labels-details-UNGER { // marque UNGER
      background-image: url(#{$base-theme-basepath}assets/images/labels/unger.png);
    }
    &.node-content--labels-details-ALS { // Air Label Score
      background-image: url(#{$base-theme-basepath}assets/images/labels/airlabel.png);
      width: 6rem !important;
      background-size: contain;
    }
    &.node-content--labels-details-JANEXFULL { // Janex Full Cycle
      background-image: url(#{$base-theme-basepath}assets/images/labels/janex.png);
    }
    &.node-content--labels-details-MAPA { //Mapa
      background-image: url(#{$base-theme-basepath}assets/images/labels/mapa.svg);
    }
    &.node-content--labels-details-SPONTEX { //Spontex
      background-image: url(#{$base-theme-basepath}assets/images/labels/spontex.svg);
    }
    &.node-content--labels-details-BPA { //PARFUM AMBIANCE
      background-image: url(#{$base-theme-basepath}assets/images/labels/bpa-2.svg);
      width: 5.2rem !important;
      background-size: contain;
    }
    &.node-content--labels-details-COSMOS { //ECOCERT COSMOS NATURAL
      background-image: url(#{$base-theme-basepath}assets/images/labels/cosmos.svg);
      width: 2.3rem !important;
    }

  }

  .product--stock-stock {
    font-style: italic;
    color: $success-color;
  }

  .product--stock-commande {
    font-style: italic;
    color: $warning-color;
  }

  .product--stock-indisponible {
    font-weight: bold;
    color: $alert-color;
  }
}

.product--related {
  a {
    &.color {
      &:hover {
        color: $tonic-color;
      }
    }
  }

  .node-teaser {
    padding-top: 0;
    min-height: rem-calc(100);
  }
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $white, 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.5), -1.8em -1.8em 0 0em rgba($white, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.7), 1.8em -1.8em 0 0em $white, 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.5), 1.8em -1.8em 0 0em rgba($white, 0.7), 2.5em 0em 0 0em $white, 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.5), 2.5em 0em 0 0em rgba($white, 0.7), 1.75em 1.75em 0 0em $white, 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.5), 1.75em 1.75em 0 0em rgba($white, 0.7), 0em 2.5em 0 0em $white, -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.5), 0em 2.5em 0 0em rgba($white, 0.7), -1.8em 1.8em 0 0em $white, -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.5), -1.8em 1.8em 0 0em rgba($white, 0.7), -2.6em 0em 0 0em $white, -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.5), -2.6em 0em 0 0em rgba($white, 0.7), -1.8em -1.8em 0 0em $white;
  }
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $white, 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.5), -1.8em -1.8em 0 0em rgba($white, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.7), 1.8em -1.8em 0 0em $white, 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.5), 1.8em -1.8em 0 0em rgba($white, 0.7), 2.5em 0em 0 0em $white, 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.5), 2.5em 0em 0 0em rgba($white, 0.7), 1.75em 1.75em 0 0em $white, 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.5), 1.75em 1.75em 0 0em rgba($white, 0.7), 0em 2.5em 0 0em $white, -1.8em 1.8em 0 0em rgba($white, 0.2), -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.5), 0em 2.5em 0 0em rgba($white, 0.7), -1.8em 1.8em 0 0em $white, -2.6em 0em 0 0em rgba($white, 0.2), -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.5), -1.8em 1.8em 0 0em rgba($white, 0.7), -2.6em 0em 0 0em $white, -1.8em -1.8em 0 0em rgba($white, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($white, 0.2), 1.8em -1.8em 0 0em rgba($white, 0.2), 2.5em 0em 0 0em rgba($white, 0.2), 1.75em 1.75em 0 0em rgba($white, 0.2), 0em 2.5em 0 0em rgba($white, 0.2), -1.8em 1.8em 0 0em rgba($white, 0.5), -2.6em 0em 0 0em rgba($white, 0.7), -1.8em -1.8em 0 0em $white;
  }
}

// User Products List
// ------
.user-products-group {
  &.is-open {
    & > .user-products-group-header:after {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}

.user-products-group-header {
  &:hover {
    cursor: pointer;
  }
}

.user-products-gamme {

}

.user-products-gamme-header {
  position: relative;
  margin-top: 2.125rem;
  margin-bottom: 1.5rem;
  padding: 0.375rem 2.5rem 0.375rem 0.25rem;
  border-bottom: 1px solid $medium-gray;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% + 0.25rem);
    right: 0.5rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent url('#{$base-theme-basepath}assets/images/pictos/toggle-arrow-blue.svg') center no-repeat;
    background-size: 0.875rem 1.5rem;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: $off-white;
  }
}

.user-products-gamme-title {
  width: 100%;
  margin: 0;
  font-size: 1.875rem;
  color: $primary-color;
}

.user-products-family {
  margin-bottom: 0;
  transition: margin-bottom 0.4s;

  &.is-open {
    margin-bottom: 1.5rem;
  }
}

.user-products-family-header {
  position: relative;
  padding: rem-calc(10) rem-calc(40) rem-calc(10) rem-calc(10);

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0.625rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    background: transparent url('#{$base-theme-basepath}assets/images/pictos/toggle-arrow-white.svg') center no-repeat;
    background-size: 0.75rem 1.25rem;
    transform: translateY(-50%);
  }
}

.user-products-family-title {
  color: $white;
  font-size: $font-size-medium;
  margin: 0;
}

.user-products-subfamily {

}

.user-products-subfamily-header {
  position: relative;
  padding: rem-calc(8) rem-calc(40) rem-calc(8) rem-calc(30);
  background: $medium-gray;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0.75rem;
    display: block;
    width: 1rem;
    height: 1rem;
    background: transparent url('#{$base-theme-basepath}assets/images/pictos/toggle-arrow-black.svg') center no-repeat;
    background-size: 0.625rem 1rem;
    transform: translateY(-50%);
  }

  &:hover {
    background: darken($medium-gray, 12%);
  }
}

.user-products-subfamily-title {
  color: $black;
  font-size: $font-size-body;
  margin: 0;
}

.user-products-item {
  border-bottom: 1px solid $medium-gray;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);

  &.disabled {
    background: $off-white;
    opacity: 0.4;
  }

  .user-products-item-nocommand {
    font-size: $font-size-small;
    display: block;
    line-height: 1;
  }

  .commerce-addfav-return {
    display: block;
    padding: rem-calc(10);
    text-align: center;
    font-size: $font-size-small;
    line-height: 1;

    [class^="icon-"] {
      margin-right: rem-calc(5);
    }

    .icon-star-empty {
      display: none;
    }

    &.checked {
      color: green;
    }

    &.error {
      color: red;
    }

    &.alert {
      color: orange;
    }
  }

  .switch {
    // margin-top: 0.125rem;
    // margin-bottom: 0.125rem;
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }
}

.user-products-item-remove {
  cursor: pointer;

  .icon-bin2 {
    margin: 0 rem-calc(5);
  }
}

.user-products-item-line-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  font-size: $font-size-body;
  line-height: 1.5;
  color: $black;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
}

// favoris
// ------
.node-commandes-type {
  .commerce-cdetype-totallines {
    font-size: $font-size-strong;
    color: $primary-color;
    @include font-family(secondary, light);
    margin-top: rem-calc(20);
  }

  .commerce-cdetype-erreur {
    display: none;
  }
}

.commerce-cdetype-import {
  margin-top: 3rem;
  // padding-top: 1.25rem;
  // padding-bottom: 1.625rem;
  padding-top: 1.375rem;
  padding-bottom: 1.75rem;
  border-top: 1px solid $dark-gray;
  border-bottom: 1px solid $dark-gray;
}

.commerce-cdetype-import-title {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.commerce-cdetype-import-description {
  margin-bottom: 1.375rem;
}

.commerce-cdetype-import-step {
  // padding-top: 1rem;
  padding-bottom: 0.75rem;
}

.commerce-cdetype-import-arrow {
  min-height: 4rem;
  margin-top: 0.5rem;
  background: transparent url('#{$base-theme-basepath}assets/images/pictos/arrow-grey.svg') center no-repeat;
  background-size: 1.875rem 4rem;
}

.commerce-cdetype-import-step-title {
  margin-bottom: 0.125rem;
  @include font-family(primary, light);
  color: $secondary-color;
  text-transform: uppercase;
}

//Page profil utilisateur
.profile {
  margin: 0;

  h3 {
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.875rem;
    padding: 0.375rem 0.25rem;
    font-size: 1.75rem;
    color: $secondary-color;
    border-bottom: 1px solid $medium-gray;
  }


  .masquerade-switch {
    background: $primary-color;
    color: $white;
    @include font-family(secondary, light);
    font-size: $font-size-small;
    padding: rem-calc(10) rem-calc(20);
    display: inline-block;
    margin: rem-calc(10) 0;

    &:hover {
      background: $primary-alt-color;
    }
  }

  .group-commercial {
    .group-infos {
      h3 {
        display: none;
      }
    }

    .column {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

// Masquerade profil
#block-masquerade-masquerade {
  padding: 1rem;
  background: $xlight-gray;
  margin-top: 3rem;

  .block-title {
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.875rem;
    padding: 0.375rem 0.25rem;
    font-size: 1.75rem;
    color: $secondary-color;
    border-bottom: 1px solid $medium-gray;
  }

  & form > div {
    display: flex;
    flex-direction: column-reverse;

    .form-item {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
}

// Liste Mes clients Commercial
.profile ~ #block-views-commerciauxclients-block {
  .block-title {
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.875rem;
    padding: 0.375rem 0.25rem;
    font-size: 1.75rem;
    color: $secondary-color;
    border-bottom: 1px solid $medium-gray;
  }

  .view-content {
    margin-left: 0;
    margin-right: 0;
  }

  .views-row {
    // margin-bottom: 1.375rem;
    margin-bottom: 1.25rem;
  }

  .views-field-field-code-client {
    width: 6.25rem;
  }

  .views-field-php {
    width: 8.25rem;
  }

  .masquerade-button-wrapper {
    // margin-top: 0.375rem;
    margin-top: 0.3125rem;
  }

  .masquerade-button {
    width: 100%;
    max-width: 6.75rem;
  }
}

.field-group-div.column {
  margin-bottom: 1.375rem;
}

.group-validation-cde .field-name-field-validateurs-cde,
.group-users-to-manage .field-name-field-users-to-manage-budgets,
.group-users-to-validate .field-name-field-users-to-validate-orders {
  & > .field-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    & > .field-item {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;

      &:nth-child(n+4) {
        margin-top: 0.875rem;
      }

      @media screen and (max-width: rem-calc(800)) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media screen and (max-width: rem-calc(480)) {
        flex: 0 0 100%;
        max-width: 100%;
      }

    }
  }
}

.group-monthly-budget .field-name-field-budget-global-mensuel {
  & > .field-items {
    & > .field-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
      @include font-family(primary, bold);
      font-size: 1.5rem;
      color: $primary-color;

      &:before {
        content: '';
        width: 3rem;
        height: 2.75rem;
        margin: 0.375rem 0.875rem 0.375rem 0;
        background: transparent url('#{$base-theme-basepath}assets/images/pictos/wallet.svg') center no-repeat;
        background-size: 3rem 2.75rem;
      }
    }
  }
}

//PAGE NODE CLASSIQUE
.node-page {
  padding-top: rem-calc(40);
  margin-top: rem-calc(40);
  border-top: 1px solid $medium-gray;
}

// ===============================================================================
// FORM
// ===============================================================================
.webform-client-form {
  & > div {
    display: flex;
    flex-flow: row wrap;
    @include grid-row();

    .form-actions {
      width: 50%;
      margin: 1rem auto;

      input, button {
        width: 100%;
        font-size: $font-size-xmedium;
      }
    }
  }
}

.fieldset-container-row > .fieldset-wrapper {
  display: flex;
  flex-flow: row wrap;
  @include grid-row();
}

.captcha {
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  justify-content: center;
  margin: 1.5rem 0;
}

// ===============================================================================
// ADMIN
// ===============================================================================
body .tabs.primary {
  background: $white;
  border: 0 none;
  border-bottom: 1px solid $primary-color;
  margin-bottom: rem-calc(20);

  & > li {
    margin-right: 0.6rem;
    display: inline-block;

    & > a {
      display: block;
      padding: 0.1rem 1rem;
      border-bottom: 1px solid $primary-color;
      margin-bottom: rem-calc(-1);
      background-color: $white;
      font-size: $font-size-small;
      color: $primary-color;
      transition: background-color 0.3s;
      @include font-family(primary, regular);
      font-size: $font-size-xmedium;

      &.is-active {
        color: $white;
        background-color: $primary-color;
        border-bottom-color: $primary-color;
      }

      &:hover,
      &:focus {
        // color: $tertiary-color;
        color: $white;
        background-color: $primary-alt-color;
      }
    }
  }
}

// ===============================================================================
// UTILITIES
// ===============================================================================
// Global
// ---------
.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.v-hidden {
  visibility: hidden;
}

.w-100,
.full-width,
.button-100 input {
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-t {
  margin-top: 0;
}

.no-margin-b {
  margin-bottom: 0;
}

.no-margin-l {
  margin-left: 0;
}

.no-margin-r {
  margin-right: 0;
}

.margin-auto {
  margin: auto;
}

.margin-t {
  margin-top: rem-calc(15);
}

.no-padding {
  padding: 0;
}

.no-padding-b {
  padding-bottom: 0;
}

.no-padding-t {
  padding-top: 0;
}

.no-padding-l {
  padding-left: 0;
}

.no-padding-r {
  padding-right: 0;
}

.full-height {
  height: 100%;
}

// Texte police style
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-transform-none {
  text-transform: none;
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.upperfirst {
  text-transform: lowercase !important;
  display: inline-block;

  &:first-letter {
    text-transform: uppercase !important;
  }
}

.text-light {
  @include font-family(primary, light);
}

.text-secondary-light {
  @include font-family(secondary, light);
}

.text-regular {
  @include font-family(primary, regular);
}

strong,
.text-bold {
  @include font-family(primary, bold);
}

// Color
.text-color-white {
  color: $white;
}

.text-color-black {
  color: $black;
}

.text-color-medium-gray {
  color: $medium-gray;
}

.text-color-dark-gray {
  color: $dark-gray;
}

.text-color-secondary {
  color: $secondary-color;
}

.text-color-light-gray {
  color: $light-gray;
}

.text-color-primary {
  color: $primary-color;
}

.text-color-success {
  color: $success-color;
}

.text-color-alert {
  color: $alert-color;
}

.bg-white {
  background: $white;
}

// Texte size
.text-size-xsmall {
  font-size: $font-size-xsmall;
}

.text-size-small {
  font-size: $font-size-small;
}

.text-size-body {
  font-size: $font-size-body;
}

.text-size-xmedium {
  font-size: $font-size-xmedium;
}

.text-size-medium {
  font-size: $font-size-medium;
}

.text-size-strong {
  font-size: $font-size-strong;
}

.text-size-xstrong {
  font-size: $font-size-xstrong;
}

.text-size-xxstrong {
  font-size: $font-size-xxstrong;
}

.text-size-xxxstrong {
  font-size: $font-size-xxxstrong;
}

.text-size-xxxxstrong {
  font-size: $font-size-xxxxstrong;
}

// Texte align
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Line-height
.line-height-body {
  line-height: 1.5;
}

// Icon position
.icon-left {
  margin-right: 0.6rem;
}

.icon-right {
  margin-left: 0.6rem;
}

.stripes {
  //position: relative;
  /*&:after{
    background: $light-gray;
    background: repeating-linear-gradient(-45deg, $white, $white 1px, $medium-gray 5px, $medium-gray 5px);
    content:'';
    display: block;
    width:100%;
    height:rem-calc(10);
    top:50%;
    margin-top: rem-calc(-5);
    position: absolute;
    left: 0;
    z-index: 0;
  }*/
  background: transparent url('#{$base-theme-basepath}assets/images/border/stripes.png') center center repeat-x;

  .stripes-text {
    background: $white;
    padding: rem-calc(5) rem-calc(10);
    color: $dark-gray;
    font-size: $font-size-strong;

    /*position: relative;
    z-index:1;*/
  }
}

// ===============================================================================
// MEDIAQUERIES
// ===============================================================================
@include breakpoint(64rem down) {
  .view-commerce-user-orders {
    .views-exposed-widgets {
      .views-widget-filter-status_1 {
        width: 100%;

        .form-item-status-1 {
          width: 97.5%;
        }
      }
    }
  }
}

// Plus petit que large
@include breakpoint(large down) {
  .block--diaporama {
    .content {
      .diaporama--item {
        .diaporama--item--body {
          width: rem-calc(300);
        }
      }
    }
  }
  .main--breadcrumb {
    padding-left: rem-calc(10);
  }
}

// Plus petit que medium
@include breakpoint(medium down) {
  .header-content-right {
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.75rem;
  }
  .header-commercial {
    margin-bottom: 0;
  }

  .menu--switch-products {
    li {
      a {
        font-size: $font-size-body;
      }
    }
  }

  .main-menu--mobile {
    background: $primary-color;
    width: 98%;
    margin: rem-calc(10) auto;
    padding: rem-calc(5) rem-calc(20);
    color: $white;
    cursor: pointer;

    .main-menu--mobile-ico {
      &:before {
        font-family: 'icomoon';
        content: "\e9bf";
      }
    }

    &.opened {
      .main-menu--mobile-ico {
        &:before {
          content: "\e9c0";
        }
      }
    }
  }

  .menu-gammes {
    display: none;

    ul {
      flex-wrap: wrap;

      li {
        width: 100%;
        border-bottom: 1px solid $medium-gray;

        a {
          height: auto;
        }

        &:hover, .is-open {
          ul {
            position: relative;
            top: 0;
            left: 0;
            min-height: rem-calc(20) !important;
            display: block;
            width: 100%;
            background: $white;

            li {
              width: 100%;

              a {
                width: 100%;
                border: 0 none;
              }

              ul {
                display: none;
              }

              &:hover, .is-open {
                ul {
                  display: block;
                  position: relative;
                  top: 0;
                  left: 0;
                  min-height: rem-calc(20) !important;
                  width: 100%;

                  li {
                    border-bottom: 0;

                    a {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sous-menu-gammes {
    display: none;
  }
  .main-menu--mobile {
    display: block;
  }

  .block--diaporama {
    .content {
      .diaporama--item {
        .diaporama--item--body {
          width: rem-calc(410);
        }

        .diaporama--item--image {
          background-position: center right;
        }
      }
    }
  }
  .footer-links {
    .content {
      a {
        padding: rem-calc(10) rem-calc(20);
      }
    }
  }
  .footer-contact.footer-contact-bis p a {
    padding: rem-calc(10);
  }
}

// Plus petit que small
@include breakpoint(small down) {
  .header-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .header-slogan {
    display: none;
  }

  .header-content-right {
    margin-top: 1.375rem;
  }
  .header-commercial {
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    padding-right: 1.25rem;

    &.header-commercial-catalogue {
      margin-left: 0;
    }
  }
  .header-commercial-icon {
    display: none;
  }
  /*.header-commercial-label, .header-commercial-name {
    display: inline-block;
  }*/
  .header-commercial-return .header-commercial-label {
    margin-right: 0.25rem;
  }

  .header-top .header--bloc-left .header--bloc-left--logo {
    margin-right: 0;
  }
  .page-ft-fds .header-search--internal {
    display: none;
  }
  //Formulaires
  .view-filters {
    form {
      width: 100%;
    }

    .views-exposed-widget {
      float: none;
      width: 100%;

      [type='text'],
      [type='password'],
      [type='date'],
      [type='datetime'],
      [type='datetime-local'],
      [type='month'],
      [type='week'],
      [type='email'],
      [type='number'],
      [type='search'],
      [type='tel'],
      [type='time'],
      [type='url'],
      [type='color'],
      textarea {
        width: 100%;
      }
    }
  }
  //Page user
  .page-user {
    .nav-profil {
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(20);
    }

    .view-filters {
      display: none;
    }
  }
  //Details produit
  .node-pages-catalogue {
    &.view-mode-full {
      .product--reference {
        margin: rem-calc(20) rem-calc(10) !important;
      }
    }
  }
  //Liste produit made in france
  .products-list.mark-madeinfrance:before {
    display: none;
  }

  //Menus
  .menu--switch-products {
    li {
      width: 45%;

      a {
        padding: rem-calc(15);
        font-size: $font-size-xmedium;
      }

      &:first-child {
        width: 55%;
      }
    }
  }
  //Moteur de recherche
  .header .views-exposed-widgets .views-exposed-widget {
    width: 100%;

    .views-widget {
      width: 100%;
    }
  }
  /*
  //Acces FT & FDS
  .header-fiches-technique p {
    a:before {
      float: left;
    }
    a:after {
      content: '';
      clear: both;
      display: block;
    }
  }*/
  .header--bloc-right a.header--ftfds:after {
    top: rem-calc(-20);
  }
  .header--bloc-right .content a.header--groupe {
    margin-right: 0;
  }
  .header--phone a {
    position: relative;
    width: 1.3rem;
    height: 1.6rem;
    display: inline-block;
    overflow: hidden;
    color: $primary-alt-color;

    &:after {
      content: '\e91c';
      position: absolute;
      top: 0.2rem;
      left: 0;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $white;

    }
  }

  //header acces rapides
  .header--connexion, .header--user-favorites {
    padding: 0 rem-calc(5);
    margin: 0;
    line-height: 1;
  }

  .block--diaporama .content .diaporama--item .diaporama--item--body {
    width: 100%;
    text-align: center;
    top: 0;
    right: 0;
    position: relative;
    font-size: 16px;
    height: auto;
    background: rgba($black, 0.7);
    padding: rem-calc(10);

    p {
      margin-bottom: 0;
    }
  }
  .block--diaporama .content .diaporama--item .diaporama--item--image {
    background-position: center;
  }


  .block--diaporama {
    margin-bottom: rem-calc(30);

    .content {
      .diaporama--item {

        .diaporama--item--mobile {
          display: block;
        }

        .diaporama--item--web {
          display: none;
        }
      }
    }
  }

  .node-pages-catalogue {
    &.view-mode-full {
      .product--images {
        margin-top: 0;
        margin-bottom: 1.25rem;
      }
    }
  }

  //Tunnel d'ackat
  .block-commerce-checkout-progress ol.commerce-checkout-progress li {
    min-height: 3.625rem;

    &:not(.active) {
      display: none;
    }
  }

  .page-checkout #commerce-checkout-form-addresses tbody tr {
    flex-wrap: wrap;

    td {
      width: 50%;

      &:nth-child(1),
      &:nth-child(2) {
        background-color: $white;
      }

      &:nth-child(3),
      &:nth-child(4) {
        background-color: $off-white;
      }
    }
  }

  .pane-data-full {
    #customer-profile-shipping,
    #customer-profile-billing {
      width: 100%;
    }
  }
}

// Tout petit écran (< iphone 5s)
@media only screen and (max-width: #{rem-calc(480)}) {

  .footer-contact .content {
    flex-direction: column;

    p {
      width: 100%;
    }
  }

  .captcha {
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    justify-content: center;
    margin: 1.625rem 0 1.375rem;
  }
}
