// ============================================================================= */
//  FUNCTIONS
// ============================================================================= */
@function rem-calc($size) {
  @return ($size / 16) * 1rem;
}

// ============================================================================= */
//  FONTS
// ============================================================================= */
@font-face {
  font-family: 'roboto';
  src: url('#{$base-theme-basepath}assets/fonts/roboto/light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'roboto';
  src: url('#{$base-theme-basepath}assets/fonts/roboto/regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'roboto';
  src: url('#{$base-theme-basepath}assets/fonts/roboto/medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'roboto';
  src: url('#{$base-theme-basepath}assets/fonts/roboto/bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-condensed';
  src: url('#{$base-theme-basepath}assets/fonts/robotocondensed/light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'roboto-condensed';
  src: url('#{$base-theme-basepath}assets/fonts/robotocondensed/regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'roboto-condensed';
  src: url('#{$base-theme-basepath}assets/fonts/robotocondensed/bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// ============================================================================= */
// FOUNDATION SETTINGS
// ============================================================================= */
$foundation-palette: (
  primary: #004986,
  secondary: #1779ba,
  tertiary: #a3cbc1,
  quatrary: #e1e9f6,
  success: #00b893,
  warning: #ffae00,
  alert: #f20000
);
$primary-alt-color: #14233a;
$tonic-color: #e93234;
$xlight-gray: #eeeeee;
$light-gray: #dadada;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$xdark-gray: #3e3e3e;
$black: #0a0a0a;
$white: #fefefe;
$off-white: #f3f3f4;
$body-background: $white;
$body-font-color: $black;
$body-font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

// ============================================================================= */
//  THEME VARIABLES
// ============================================================================= */
// Fonts family
$font-family-primary: 'roboto', Verdana, sans-serif;
$font-family-secondary: 'roboto-condensed', Helvetica, Arial, sans-serif;

// Fonts size
$font-size-xsmall: rem-calc(12);
$font-size-small: rem-calc(14);
$font-size-body: rem-calc(16);
$font-size-xmedium: rem-calc(18);
$font-size-medium: rem-calc(20);
$font-size-strong: rem-calc(24);
$font-size-xstrong: rem-calc(30);
$font-size-xxstrong: rem-calc(36);
$font-size-xxxstrong: rem-calc(42);
$font-size-xxxxstrong: rem-calc(120);

// Color sociaux
$facebook-color: #3b5998;
$pinterest-color: #cb2027;
$youtube-color: #b31217;

// Global Loader
$global-loader-color: $white;
$global-loader-background: rgba($black, 0.45);

// Misc.
$footer-lightness: 5%;
$footer-button-color: scale-color($primary-alt-color, $saturation: -40%, $lightness: 12.5%);

// ============================================================================= */
// MIXINS
// ============================================================================= */
// Font family
@mixin font-family($font, $weight) {
  @if $font == 'primary' {
    font-family: $font-family-primary;
    @if $weight == 'light' {
      font-weight: 300;
    } @else if $weight == 'regular' {
      font-weight: 400;
    } @else if $weight == 'medium' {
      font-weight: 500;
    } @else if $weight == 'bold' {
      font-weight: 700;
    }
  } @else if $font == 'secondary' {
    font-family: $font-family-secondary;
    @if $weight == 'light' {
      font-weight: 300;
    } @else if $weight == 'regular' {
      font-weight: 400;
    } @else if $weight == 'medium' {
      font-weight: 500;
    } @else if $weight == 'bold' {
      font-weight: 700;
    }
  }
}

// ============================================================================= */
// ANIMATIONS
// ============================================================================= */

// ============================================================================= */
// ICOMOON
// ============================================================================= */
@font-face {
  font-family: 'icomoon';
  src:  url('#{$base-theme-basepath}assets/fonts/icomoon/icomoon.eot?y3v2qz');
  src:  url('#{$base-theme-basepath}assets/fonts/icomoon/icomoon.eot?y3v2qz#iefix') format('embedded-opentype'),
  url('#{$base-theme-basepath}assets/fonts/icomoon/icomoon.ttf?y3v2qz') format('truetype'),
  url('#{$base-theme-basepath}assets/fonts/icomoon/icomoon.woff?y3v2qz') format('woff'),
  url('#{$base-theme-basepath}assets/fonts/icomoon/icomoon.svg?y3v2qz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelop2:before {
  content: "\e905";
}
.icon-cart2:before {
  content: "\e904";
}
.icon-price:before {
  content: "\e903";
}
.icon-reseau:before {
  content: "\e901";
}
.icon-societe:before {
  content: "\e902";
}
.icon-keyboard_arrow_right:before {
  content: "\e908";
}
.icon-keyboard_arrow_left:before {
  content: "\e909";
}
.icon-keyboard_arrow_up:before {
  content: "\e906";
}
.icon-keyboard_arrow_down:before {
  content: "\e907";
}
.icon-home:before {
  content: "\e900";
}
.icon-pencil2:before {
  content: "\e90a";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-files-empty:before {
  content: "\e925";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-file-zip:before {
  content: "\e92b";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-paste:before {
  content: "\e92d";
}
.icon-folder:before {
  content: "\e92f";
}
.icon-folder-open:before {
  content: "\e930";
}
.icon-folder-plus:before {
  content: "\e931";
}
.icon-folder-minus:before {
  content: "\e932";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-drawer:before {
  content: "\e95c";
}
.icon-drawer2:before {
  content: "\e95d";
}
.icon-box-add:before {
  content: "\e95e";
}
.icon-download:before {
  content: "\e960";
}
.icon-user:before {
  content: "\e971";
}
.icon-search:before {
  content: "\e986";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-menu3:before {
  content: "\e9bf";
}
.icon-menu4:before {
  content: "\e9c0";
}
.icon-download2:before {
  content: "\e9c5";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-google-plus:before {
  content: "\ea8b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-keyboard_arrowdouble:before {
  content: "\e90b";
}
.icon-shop:before {
  content: "\e90c";
}
.icon-map:before {
  content: "\e90d";
}
.icon-tel:before {
  content: "\e91c";
}
.icon-web:before {
  content: "\e91a";
}
.icon-envelop3:before {
  content: "\e90f";
}
.icon-bin2:before {
  content: "\e90e"; 
}
.icon-box:before {
  content: "\e910";
}
.icon-box-check:before {
  content: "\e911"; 
}
.icon-coins:before {
  content: "\e912";
}
.icon-coins-check:before {
  content: "\e913";
}
.icon-users:before {
  content: "\e914"; 
}
.icon-address-book-check:before {
  content: "\e915"; 
}
.icon-commercial:before {
  content: "\e916"; 
}
