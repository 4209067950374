// ============================================================================= */
// FOUNDATION SETTINGS
// ============================================================================= */

// ============================================================================= */
//  VARIABLES
// ============================================================================= */
// Colors gammes Chimie
//1 - INNOVEYR - INNOVEYR  - CHIMIE - 318733
$innoveyr: #318733;
//2 - SOLP - SOLS PROTEGES - CHIMIE - 9d9c9c
$solp: #9d9c9c;
//3 - SOLS - SOLS SPECIFIQUES - CHIMIE - 895b48
$sols:  #895b48;
//4 - HYG - HYGIENE GENERALE - CHIMIE - 5389b2
$hyg: #5389b2;
//5 - ALI - ALIMENTAIRE - CHIMIE - ffdd00
$ali: #efa413;
//6 - LINGE - HYGIENE DU LINGE - CHIMIE - 0069b4
$linge: #0069b4;
//7 - PRO - PRODUITS PROFESSIONNELS - CHIMIE - 9d9c9c
$pro: #9d9c9c;
//8 - ORM - ORMEA - CHIMIE - ed721e
$orm: #ed721e;
//9 - ECOCERT - ECOCERT - CHIMIE - 123e6a
$ecocert: #123e6a;
//10 - ECOLABEL - ECOLABEL  - CHIMIE - 318733
$ecoabel: #318733;

// Colors gammes Consommable
//1 - SAN - SANITAIRES - CONSOMMABLE - e84142
$san: #e84142;
//2 - DECH - COLLECTE DECHETS - CONSOMMABLE - 4b76ba
$dech: #4b76ba;
//3 - PMAT - EQUIPEMENT DE NETTOYAGE - CONSOMMABLE - 53b898
$pmat: #53b898;
//4 - GMAT - MATERIEL ET EQUIPEMENT ELEC - CONSOMMABLE - ec6d9b
$gmat: #ec6d9b;
//5 - EPI - EQUIPEMENT DE PROTECTION INDIV - CONSOMMABLE - 62bae9
$epi: #62bae9;
//6 - RESHOT - RESTAURATION/HOTELLERIE - CONSOMMABLE - ffdc93
$reshot: #f1bd52;
//7 - AUTRMAT - AUTRE MATERIEL ET FOURN INDUS - CONSOMMABLE - 7d6d99
$autrmat: #7d6d99;
//8 - DISQUES
$disques: #767988;
